import React from "react";
import 'react-phone-input-2/lib/style.css'

import FileUploadBlock from "../../../components/blocks/fileUpload";
import styles from "./SupplierForm.module.scss";


function SupplierForm({handleChange, formData, setFile}:any) {
  return (
    <>
      <div className={`row ${styles.formSection}`}>
        <div className="col-12">
          <div className="form-group">
            <label className="form-label" htmlFor="name">
              Name:
            </label>
            <input
              type="text"
              id="name"
              onChange={handleChange}
              value={formData.name}
              disabled
              required
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="rcNumber">
            RC Number:
            </label>
            <input
              type="text"
              id="rcNumber"
              onChange={handleChange}
              value={formData.rcNumber}
              disabled
              required
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              onChange={handleChange}
              value={formData.email}
              disabled
              required
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="contact">
              Tel:
            </label>
            <input
              type="contact"
              id="contact"
              onChange={handleChange}
              value={formData.contact}
              disabled
              required
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="billingAddress">
              Registered Billing Address:
            </label>
            <textarea
              id="billingAddress"
              onChange={handleChange}
              value={formData.billingAddress}
              required
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="state">
              State:
            </label>
            <input
              type="text"
              id="state"
              onChange={handleChange}
              value={formData.state}
              required
            />
          </div>
        </div>
      </div>
      <div className={`row ${styles.formSection} ${styles.sectionDeco}`}>
        <div className="col-6">
        <div className="form-group">
            <label className="form-label" htmlFor="bankName">
              Bank Name:
            </label>
            <input
              type="text"
              id="bankName"
              onChange={handleChange}
              value={formData.bankName}
              required
            />
          </div>
        </div>
        <div className="col-6"></div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="acNumber">
              Account Number:
            </label>
            <input
              type="text"
              id="acNumber"
              onChange={handleChange}
              value={formData.acNumber}
              required
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="acName">
              Account Name:
            </label>
            <input
              type="text"
              id="acName"
              onChange={handleChange}
              value={formData.acName}
              required
            />
          </div>
        </div>
      </div>
      <div className={`row ${styles.formSection}`}>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="representativeName">
              Office representative name:
            </label>
            <input
              type="text"
              id="representativeName"
              onChange={handleChange}
              value={formData.representativeName}
              disabled
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="designation">
              Designation:
            </label>
            <input
              type="text"
              id="designation"
              onChange={handleChange}
              value={formData.designation}
              disabled
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="representativeTel">
              Tel:
            </label>
            <input
              type="text"
              id="representativeTel"
              onChange={handleChange}
              value={formData.representativeTel}
              disabled
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="representativeEmail">
              Email:
            </label>
            <input
              type="email"
              id="representativeEmail"
              onChange={handleChange}
              value={formData.representativeEmail}
              disabled
            />
          </div>
        </div>
      </div>
      <div className={`row ${styles.formSection} ${styles.sectionDeco}`}>
        <div className={`col-12 ${styles.mb40}`}>
          <div className="form-group">
            <label className="form-label">
              Company registered certifications
            </label>
            <div style={{backgroundColor: '#fff', padding: '10px 20px'}}>
              <FileUploadBlock id='supplierCompanyCertificate' onUpload={(file: any)=>{setFile({"supplierCompanyCertificate":file})}}/>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="form-label">
              Bank statement with company stamp (3 years)
            </label>
            <div style={{backgroundColor: '#fff', padding: '10px 20px'}}>
              <FileUploadBlock id='supplierCompanyStamp' onUpload={(file: any)=>{setFile({"supplierCompanyStamp":file})}}/>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="form-label">
            Signed Platform Agreement 
            </label>
            <div style={{backgroundColor: '#fff', padding: '10px 20px'}}>
              <FileUploadBlock id='agreement' onUpload={(file: any)=>{setFile({"agreement":file})}}/>
            </div>
          </div>
        </div>
      </div>
      <div className={`row ${styles.formSection}`}>
        <div className={styles.buttonGroup}>
          <button type="submit" className={`${styles.btnRed}`}>Submit</button>
        </div>
      </div>
    </>
  );
}

export default SupplierForm;
