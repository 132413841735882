import React, {useEffect, useState} from "react";
import Upload from "../../upload";
import styles from "./style.module.scss";
import { CloseIcon, DocumentIcon } from '../../svg';
import {getFileDetails} from '../../../utils'

interface uploadType {
  isFile?: boolean
  onUpload: Function
  value?: any,
  disabled?: boolean
}


function UploadLogoCard({isFile, onUpload, disabled=false, value}: uploadType) {
  //TODO: handleChange to be updated based on props. Below implementation if for testing purpose.
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [downloadableFile, setDownloadableFile] = useState(false)


  useEffect( () => {
    if(value && value.originalName) {
      setFileName(`...${value.originalName.slice(-30)}`)
      setDownloadableFile(true)
      setFile(value)
    }
   }, [value])
  
  const handleChange = (e: any) => {
    if (e.target.files[0]) {

      let files = e.target.files[0]; 
      // onUpload({file: files});
      setFileName(e.target.files[0].name);
      const file = URL.createObjectURL(e.target.files[0]);
      setFile(file);
      e.target.value = null;
      getFileDetails(files).then( (fileDetails) => {
        onUpload({file: fileDetails})
      })
    }
  };

  const handleRemove =(e:any) =>{
    if(!disabled){
      setFile('')
    }
  }
  return (
    <div className={styles.card}>
      <div className="form-heading border-bottom-1">Company Logo</div>

      <div className={styles.uploadBlock}>
        <div className={styles.uploadBlockInner}>
          <Upload
            disabled={disabled}
            id="supplierLogo"
            type="img"
            onChange={handleChange}
          />
        </div>
      </div>

      {!isFile && file !== '' && (
        <div className={styles.displayBlock}>
          <div className={styles.displayBlockInner}>
            <div>
              <img
                src={(file as any).filePath ? (file as any).filePath : file}
                alt="uploaded company logo"
                className={styles.image}
              />
            </div>
            <div className={styles.closeIcon}>
              <CloseIcon onClick={handleRemove} />
            </div>
          </div>
        </div>
      )}

      {isFile && file !=='' && (
        <div className={`${styles.fileDetails}`}>
          <span>{fileName}</span>
          <div className="d-flex align-items-center">
            <DocumentIcon width="32" height="36" />
            <div className={styles.closeIcon}>
              <CloseIcon onClick={handleRemove} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadLogoCard;
