import React, { ReactNode } from "react";
import Sidebar from "../../components/sidebar";
import Header from "../../components/header";
import Footer from "../../components/footer";
import styles from "./style.module.scss";

function MainLayout({ children }: { children: ReactNode }) {
  return (
    <div className={styles.layout}>
      <Header />
      <Sidebar />
      <div className={styles.layoutInner}>{children}</div>
      <Footer variant="mainLayoutVariant" />
    </div>
  );
}

export default MainLayout;
