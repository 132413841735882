import React, {useState} from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import FileUploadBlock from "../../../components/blocks/newFileUpload";
import styles from "./SupplierForm.module.scss";


function SupplierForm({handleChange, formData, setFile}:any) {
  const [contact, setContact] = useState('234');
  const [representativeTel, setRepresentativeTel] = useState('234');

  return (
    <div style={{ paddingBottom: 100 }}>
      <section className={`${styles.FormSection}`}>
        <div style={{ marginBottom: 20 }}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            onChange={handleChange}
            value={formData.name || ''}
            disabled={formData.isActivated}
            required
          />
        </div>
        <div style={{ marginBottom: 20 }}>
          <label htmlFor="rcNumber">RC Number:</label>
          <input
            type="text"
            id="rcNumber"
            onChange={handleChange}
            value={formData.rcNumber || ''}
            disabled={formData.isActivated}
            required
          />
        </div>
        <div className="d-flex" style={{ gap: 20, marginBottom: 20 }}>
          <div style={{ width: '50%' }}>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              onChange={handleChange}
              required
              value={formData.email || ''}
              disabled={formData.isActivated}
            />
          </div>
          <div style={{ width: '50%' }}>
            <label htmlFor="contact">Tel:</label>
            <input
              type="contact"
              id="contact"
              onChange={handleChange}
              value={formData.contact || ''}
              disabled={formData.isActivated}
              required
            />
          </div>
        </div>
        <div className="d-flex" style={{ gap:20 }}>
          <div className="d-flex flex-column" style={{ width: '50%' }}>
            <label htmlFor="billingAddress">Registered Billing Address:</label>
            <textarea
              id="billingAddress"
              className="w-100"
              value={formData.billingAddress || ''}
              disabled={formData.isActivated}
              onChange={handleChange}
            />
          </div>
          <div style={{ width: '50%' }}>
            <label htmlFor="state">State:</label>
            <input
              type="text"
              id="state"
              value={formData.state || ''}
              disabled={formData.isActivated}
              onChange={handleChange}
            />
          </div>
        </div>
      </section>

      <section className={`${styles.FormSection} ${styles.SectionDeco}`}>
        <div style={{ marginBottom: 20, width: '50%', paddingRight: 10 }}>
          <label htmlFor="bankName">Bank Name:</label>
          <input
            type="text"
            id="bankName"
            value={formData.bankName || ''}
            disabled={formData.isActivated}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex" style={{ gap: 20, marginBottom: 20 }}>
          <div style={{ width: '50%' }}>
            <label htmlFor="acNumber">Account Number:</label>
            <input
              type="text"
              id="acNumber"
              value={formData.acNumber || ''}
              disabled={formData.isActivated}
              onChange={handleChange}
            />
          </div>
          <div style={{ width: '50%' }}>
            <label htmlFor="acName">Account Name:</label>
            <input
              type="text"
              id="acName"
              value={formData.acName || ''}
              disabled={formData.isActivated}
              onChange={handleChange}
            />
          </div>
        </div>
      </section>

      <section className={`${styles.FormSection}`}>
        <div className="d-flex" style={{ gap: 20, marginBottom: 20 }}>
          <div style={{ width: '50%' }}>
            <label htmlFor="representativeName">Office representative name:</label>
            <input
              type="text"
              id="representativeName"
              value={formData.representativeName || ''}
              disabled={true}
              onChange={handleChange}
              required
            />
          </div>
          <div style={{ width: '50%' }}>
            <label htmlFor="designation">Designation:</label>
            <input
              type="text"
              id="designation"
              onChange={handleChange}
              value={formData.designation || ''}
              disabled={true}
              required
            />
          </div>
        </div>
        <div className="d-flex" style={{ gap: 20, marginBottom: 20 }}>
          <div style={{ width: '50%' }}>
            <label htmlFor="representativeTel">Tel:</label>
            <input
              type="representativeTel"
              id="representativeTel"
              onChange={handleChange}
              value={formData.representativeTel || ''}
              disabled={true}
              required
            />
          </div>
          <div style={{ width: '50%' }}>
            <label htmlFor="representativeEmail">Email:</label>
            <input
              type="email"
              id="representativeEmail"
              onChange={handleChange}
              value={formData.representativeEmail || ''}
              disabled={true}
              required
            />
          </div>
        </div>
      </section>

      <section className={`${styles.FormSection} ${styles.SectionDeco}`}>
        <div className="d-flex flex-column" style={{ marginBottom: 25 }}>
          <label style={{ padding: 10 }}>Company registered certifications</label>
          <div style={{ backgroundColor: '#ffffff' }}>
            <FileUploadBlock
              disabled={formData.isActivated}
              value={formData.supplierCompanyCertificate}
              id='supplierCompanyCertificate'
              onUpload={
                (file: any)=>{setFile({"supplierCompanyCertificate":file})}
              }
            />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ marginBottom: 25 }}>
          <label style={{ padding: 10 }}>Bank statement with company stamp (3 years)</label>
          <div style={{ backgroundColor: '#ffffff' }}>
            <FileUploadBlock
              disabled={formData.isActivated}
              value={formData.supplierCompanyStamp}
              id='supplierCompanyStamp'
              onUpload={
                (file: any)=>{setFile({"supplierCompanyStamp":file})}
              }
            />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ marginBottom: 25 }}>
          <label style={{ padding: 10 }}>Signed Platform Agreement </label>
          <div style={{ backgroundColor: '#ffffff' }}>
            <FileUploadBlock
              disabled={formData.isActivated}
              value={formData.agreement}
              id='agreement'
              onUpload={
                (file: any)=>{setFile({"agreement":file})}
              }
            />
          </div>
        </div>
      </section>
      {/* <div className={`row ${styles.formSection}`}>
        <div className={styles.buttonGroup}>
          <button disabled type="submit" className={`${styles.btnRed}`}>Edit Supplier</button>
          <button onClick={()=> {console.log("Cancel supplier")}}className={`${styles.btnGrey}`}>Cancel</button>
        </div>
      </div> */}
    </div>
  );
}

export default SupplierForm;
