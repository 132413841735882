import React, { useState, useEffect } from 'react';
import styles from './invoiceForm.module.scss';
import placeHolderLogo from '../../../assets/img/place-holder-image.png';
import moment from 'moment';
import { pathOr } from 'ramda';

function InvoiceForm({ formData }: any) {

  const { invoice, currency, supplierDetails, clientDetails } = formData;

  const [totalAmount, setTotalAmount] = useState('');

  useEffect(() => {
    const sum =
      (parseFloat(invoice?.value?.pretaxValue) || 0) + (parseFloat(invoice?.value?.taxValue) || 0);
    setTotalAmount(sum.toLocaleString());
  }, [invoice]);

  return (
    <div className={`${styles.FormSection}`}>
      <section className={styles.InvoiceInfo}>
        <div className="d-flex align-items-start justify-content-between">
          <div>
            <span>Invoice No: </span>
            <span style={{ fontWeight: 'bold' }}>{invoice?.invoiceNumber || ''}</span>
          </div>

          <div>
            <div className="d-flex justify-content-end" style={{ marginBottom: 15 }}>
              <span>Issue Date:</span>
              <span style={{ fontWeight: 'bold', width: 120, textAlign: 'right' }}>
                {invoice ? moment(invoice.issueDate).format('D MMM YYYY') : ''}
              </span>
            </div>

            <div className="d-flex justify-content-end">
              <span>Due Date:</span>
              <span style={{ fontWeight: 'bold', width: 120, textAlign: 'right' }}>
                {invoice ? moment(invoice.dueDate).format('D MMM YYYY') : ''}
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className={`d-flex justify-content-between gap-20 ${styles.FormBase}`}>
        <div className="d-flex flex-column flex-grow-3 gap-20">
          <div className={`d-flex ${styles.InvoiceData}`}>
            <span>Supplier RC No:</span>
            <span>{supplierDetails?.registrationNumber || ''}</span>
          </div>

          <div className={`d-flex ${styles.InvoiceData}`}>
            <span>Supplier Name:</span>
            <span>{supplierDetails?.name || ''}</span>
          </div>

          <div className={`d-flex ${styles.InvoiceData}`}>
            <span>Supplier Email:</span>
            <span>{supplierDetails?.contactDetails?.email || ''}</span>
          </div>

          <div className={`d-flex ${styles.InvoiceData}`}>
            <span>Supplier Address:</span>
            <span>{supplierDetails?.contactDetails?.billingAddress || ''}</span>
          </div>
        </div>

        <div className={`d-flex align-items-center justify-content-center flex-grow-2`}>
          <img
            src={pathOr(placeHolderLogo, ['companyLogo', 'filePath'], supplierDetails)}
            width={180}
            alt="logo"
          />
        </div>
      </section>
      
      <section className={`d-flex justify-content-between gap-20 ${styles.FormBase}`}>
        <div className="d-flex flex-column flex-grow-3 gap-20">
          <div className={`d-flex ${styles.InvoiceData}`}>
            <span>Client No:</span>
            <span>{clientDetails?.registrationNumber || ''}</span>
          </div>

          <div className={`d-flex ${styles.InvoiceData}`}>
            <span>Client Name:</span>
            <span>{clientDetails?.name || ''}</span>
          </div>

          <div className={`d-flex ${styles.InvoiceData}`}>
            <span>Client Email:</span>
            <span>{clientDetails?.contactDetails?.email || ''}</span>
          </div>

          <div className={`d-flex ${styles.InvoiceData}`}>
            <span>Client Address:</span>
            <span>{clientDetails?.contactDetails?.billingAddress || ''}</span>
          </div>
        </div>

        <div className={`d-flex align-items-center justify-content-center`} style={{ flexGrow: 2 }}>
          <img
            src={pathOr(placeHolderLogo, ['companyLogo', 'filePath'], clientDetails)}
            width={180}
            alt="logo"
          />
        </div>
      </section>

      <section style={{ marginTop: 50 }}>
        <table className={styles.InvoiceTable}>
          <tbody>
            <tr
              className={`d-flex justify-content-between align-items-center py-px-15 ${styles.TotalSection}`}>
              <td style={{ fontWeight: 'bold', fontSize: 20 }}>Total Value</td>
              <td style={{ fontSize: '28px', fontWeight: 'bold' }}>
                {(invoice?.value?.currency || currency) === 'USD' ? '$' : '₦'} {totalAmount}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default InvoiceForm;
