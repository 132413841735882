import React, {useReducer, useState, useEffect} from "react";
import FormCard from "../../../components/cards/form";
import { useHistory } from "react-router-dom";
import UploadLogoCard from "../../../components/cards/uploadLogo";
import SupplierForm from "./SupplierForm";
import Form from '../../../components/form';
import Spinner from "../../../components/loader/Spinner";
import ErrorAlert from "../../../components/error";
import styles from './activation.module.scss';
import Footer from "../../../components/footer";
import logo from '../../../assets/img/sc-logo.png';
import {getCompanyDetailsUsingActivationCode, completeSupplierOnboarding} from '../../../api'


// TODO move to react router path params
function getToken(locationHref: String) {
  const location = locationHref.split("/")
  const token = location.pop()
  if(token != "activate") {
    return token
  }else {
    return null
  }

}

function companyDetailsToFormData(companyDetails: any) {
  return {
    name: companyDetails.name,
    rcNumber: companyDetails.registrationNumber,
    email: companyDetails.contactDetails.email,
    contact: companyDetails.contactDetails.telephone,
    billingAddress: companyDetails.contactDetails.billingAddress,
    state: companyDetails.contactDetails.state,
    representativeName: companyDetails.officialRepresentative.name,
    designation: companyDetails.officialRepresentative.designation,
    representativeEmail: companyDetails.officialRepresentative.emailAddress,
    representativeTel: companyDetails.officialRepresentative.phoneNumber,
    bankName: companyDetails.bankDetails.bankName,
    acNumber: companyDetails.bankDetails.accountNumber,
    acName: companyDetails.bankDetails.accountName
  }
}

function ActivateSupplier() {
  const [formData, setFormData] = useReducer((a:any, c:any)=> ({...a, ...c}), {});
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error occurred! Please try again')

  const token = getToken(window.location.href)

  console.log('token', token)

  const history = useHistory();
  useEffect(() => {

    setLoading(true)

    getCompanyDetailsUsingActivationCode(token).then(
      (response) => {
        setLoading(false);
        const companyDetails = response.data
        const defaultFormData = companyDetailsToFormData(companyDetails)
        setFormData(defaultFormData)
        console.log(companyDetails, defaultFormData)
      }
    ).catch((error: any) => {
      setLoading(false);
      setError(true);
      if(error.response && error.response.status === 404){
        setErrorMessage('Invalid Token')
      }
      console.log(error)
    });

  }, [token])

  useEffect(() => {
    let errorTime: NodeJS.Timeout, successTime: NodeJS.Timeout;
    if (isError) {
      errorTime = setTimeout(() => {
        setError(false);
      }, 2000);
    }

    return () => {
      clearTimeout(errorTime);
      clearTimeout(successTime);
    };
  }, [isError, isSuccess]);

  const handleSubmit = (e:any) => {
    console.log('Here')
    e.preventDefault();
    setLoading(true);
    const form = {
      "activationToken": token,
      "name": formData.name,
      "registrationNumber": formData.rcNumber,
      "contactDetails": {
        "email": formData.email,
        "telephone": formData.contact,
        "billingAddress": formData.billingAddress,
        "state": formData.state
      },
      "bankDetails": {
        "bankName": formData.bankName,
        "accountNumber": formData.acNumber,
        "accountName": formData.acName
      },
      // "officialRepresentative": {
      //   "name": formData.representativeName,
      //   "designation": formData.designation,
      //   "emailAddress": formData.representativeEmail
      // },
      "companyLogo": formData.file,
      "registrationCertificate": formData.supplierCompanyCertificate,
      "bankStatement": formData.supplierCompanyStamp,
      "signedPlatformAgreement":formData.agreement
    }
    console.log(form)


    if(isValidateData(form)){
      completeSupplierOnboarding(form).then( () => {
        setLoading(false);
        setSuccess(true);
        // setTimeout(()=> history.push(paths.suppliers), 2000)
      })
      .catch((error: any) => {
        setLoading(false);
        setError(true);
        console.log(error)
      });
    } else {
      console.log('Validation failed ')
      setLoading(false);
      setError(true);
    }
    
  }

  const handleChange = (e: any): any => {
    let data:any = {...formData};
    data[e.target.id]= e.target.value.trim();
    console.log("formDataChange", data)
    setFormData(data);
  }

  const isValidateData= (obj: any):boolean => {
    for(let k in obj){
      if(typeof(obj[k]) === "string" && obj[k].trim().length < 1){
        return false;
      }
    }
    return true
  };

  return (
    <div className={`d-flex flex-column ${styles.activation}`} style={{ minHeight: '100vh' }}>
      <div className={styles.header}>
        <div style={{paddingLeft:158}}>
          <img src={logo} alt="sc logo" width="224px" height="88px"/>
          <div className="row" style={{padding:'100px 158px 0 0', marginRight:0}}>
            <div className="col-4">
              <h1>Supplier <br/>Onboarding Process</h1>
            </div>
            { !isSuccess && (
              <div className="col-8">
                <p style={{color: 'rgba(4, 12, 71, 0.49)', lineHeight:'26px', fontSize:22}}>Fill all the details and please complete the process</p>
              </div>
            )}
          </div>
        </div>
      </div>
      { isSuccess ? (
        <div className="flex-grow-1">
          <div style={{textAlign: 'center', maxWidth: 700, margin: '0 auto 100px', color: '#787E9F', fontSize: 36 }}> 
            Thank  you for submitting the documentation. You will be sent an activation email post verification
          </div>
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          {isLoading && <Spinner />}
          {isError && <ErrorAlert message={errorMessage}/>}
          <section>
            <div className="row">
              <div className="col-xl-8 order-2 order-xl-1">
                <FormCard>
                  <div className="form-heading">Supplier</div>
                  <hr  className="horizontal-line" />
                  <div className="col-12">
                    <SupplierForm handleChange={handleChange} formData={formData} setFile={setFormData}/>
                  </div>
                </FormCard>
              </div>
              <div className="col-xl-4 order-1 order-xl-2">
                <UploadLogoCard onUpload={setFormData}/>
              </div>
            </div>
          </section>
        </Form>
      )}
    <div style={{backgroundColor:'white'}}>
      <Footer/>
    </div>
  </div>
  );
}

export default ActivateSupplier;
