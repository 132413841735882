import React, { useState } from 'react';
import styles from './style.module.scss';
import UserProfileCard from '../../../components/cards/userProfileCard';

interface IUserList {
  title: string;
  data: any;
  link: string;
}

const UserList = ({ title, data, link }: IUserList) => {
  const [isShowingAll, setIsShowingAll] = useState(false);

  const toggleIsShowingAll = () => {
    setIsShowingAll((previousValue) => !previousValue);
  };

  const buttonText = isShowingAll ? 'View less' : 'View all';

  const ListUser = data.map((el: any) => {
    return (
      <UserProfileCard
        className={!isShowingAll ? styles.card : ''}
        key={`inCard-${el.id}`}
        link={`${link}/${el.id}`}
        userDetails={el}
      />
    );
  });

  return (
    <>
      <section
        className="section-heading d-flex justify-content-between align-items-center"
        style={{ marginRight: 62 }}>
        <p className={styles.invoiceTitle}>{title}</p>
        <button onClick={toggleIsShowingAll} className={styles.btnStyle}>
          {buttonText}
        </button>
      </section>
      <div className={styles.cardContainerLayout}>
        <div className={isShowingAll ? styles.cardContainerAll : styles.cardContainer}>
          {ListUser}
        </div>
      </div>
    </>
  )
};

export default UserList;
