import React, { useState, useEffect, useContext } from 'react';
import CardTag from '../../../components/cards/cardTag';
import SearchFormInput from '../../../components/form/search';
import Form from '../../../components/form';
import InvoiceList from './invoiceList';
import { pathProvider } from '../../../constants';
import { UserContext } from '../../../App';
// import InvoiceResultList from './invoiceResultList';
import { getClientInvoiceSummary, search } from '../../../api';
import Spinner from '../../../components/loader/Spinner';
import { filter, includes, propSatisfies, __ } from 'ramda';
import styles from './style.module.scss';
import { InvoiceData } from '../../../interface'
import { useLocation, useHistory } from "react-router-dom";

const newInvoicesStatus = ['New', 'OptedInForDiscounting', 'ApprovedForDiscounting'];
const offeredStatus = ['InvoiceQuoted', 'InvoiceQuoteAccepted'];
const discountedStatus = ['InvoiceDiscounted'];
const paidStatus = ['InvoicePaid'];
const overDueStatus = ['InvoiceOverDue'];


const InvoiceSummary = () => {
  const [data, setData] = useState<Array<InvoiceData>>([]);
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [filterData, setFilterData] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  const newInvoices = filter<InvoiceData>(propSatisfies(includes(__, newInvoicesStatus), 'status'), data);
  const offeredInvoices = filter<InvoiceData>(propSatisfies(includes(__, offeredStatus), 'status'), data);
  const discountedInvoices = filter<InvoiceData>(propSatisfies(includes(__, discountedStatus), 'status'), data);
  const processedInvoices = filter<InvoiceData>(propSatisfies(includes(__, paidStatus), 'status'), data)
  const overDueInvoices = filter<InvoiceData>(propSatisfies(includes(__, overDueStatus), 'status'), data)

  const location = useLocation();
  const history = useHistory();
  const { role } = useContext(UserContext);
  const nav = pathProvider(role);

  const fetchInvoiceData = (query: any): Promise<any> => {
    return query ? search(query) : getClientInvoiceSummary();
  };

  const getInvoiceData = (query: any) => {
    setLoading(true);
    fetchInvoiceData(query)
    .then(res => {
      setData(res.data?.data || res.data);
      setLoading(false);
      setIsSearch(!!query)
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!searchQuery && isSearch) {
      getInvoiceData(null);
    }
  }, [searchQuery]);


  const onFilterClick = (status: string) => {
    setFilterStatus(status === filterStatus ? '' : status);
  };
  
  useEffect(() => {
    const filterData: any[] = filter<InvoiceData>(propSatisfies(includes(__, [filterStatus]), 'status'), data);
    setFilterData(filterData);
  }, [filterStatus, data])

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchQuery) {
      getInvoiceData(searchQuery);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search).get('invoice');
    getInvoiceData(query);
  }, [location]);

  return (
    <>
      {loading && <Spinner />}

      <section className="page-heading">Dashboard {'>'} Invoices</section>

      <section
        className={`d-flex justify-content-between align-items-center ${styles.searchSection}`}>
        <div className="flex-grow-1">
          <Form onSubmit={handleSearch}>
            <SearchFormInput
              className={styles.searchFormInput}
              placeholder="Search Invoice number, Invoice value, Ref number, Supplier name, Supplier RC Number"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </Form>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span>Filter by</span>
          <div className="d-flex flex-wrap align-items-center" style={{ marginLeft: 14, gap: 5, width: 325 }}>
            <CardTag
              isActive={filterStatus === 'New'}type="New" text="New" onClick={() => onFilterClick('New')} />
            <CardTag
              isActive={filterStatus === 'OptedInForDiscounting'}type="Pending" text="Pending Approval" onClick={() => onFilterClick('OptedInForDiscounting')} />
            <CardTag
              isActive={filterStatus === 'ApprovedForDiscounting'}type="Accepted" text="Approved" onClick={() => onFilterClick('ApprovedForDiscounting')} />
            <CardTag
              isActive={filterStatus === 'InvoiceQuoted'}type="Accepted" text="Offered" onClick={() => onFilterClick('InvoiceQuoted')} />
            <CardTag
              isActive={filterStatus === 'InvoiceQuoteAccepted'}type="Accepted" text="Accepted" onClick={() => onFilterClick('InvoiceQuoteAccepted')} />
            <CardTag
              isActive={filterStatus === 'InvoiceDiscounted'}type="Accepted" text="Discounted" onClick={() => onFilterClick('InvoiceDiscounted')} />
            <CardTag
              isActive={filterStatus === 'InvoicePaid'}type="Accepted" text="Processed" onClick={() => onFilterClick('InvoicePaid')} />
            <CardTag
              isActive={filterStatus === 'InvoiceOverDue'}type="Rejected" text="Overdue" onClick={() => onFilterClick('InvoiceOverDue')} />
          </div>
        </div>
      </section>

      <section>
        {
          filterStatus ? (
            <InvoiceList group="Filter results" link={nav.invoices} data={filterData} />
          ) : isSearch ? (
            <InvoiceList group="Search results" link={nav.invoices} data={data} />
          ) : (
            <>
              <InvoiceList group="New" link={nav.invoices} data={newInvoices} />
              <InvoiceList group="Offered" link={nav.invoices} data={offeredInvoices} />
              <InvoiceList group="Discounted" link={nav.invoices} data={discountedInvoices} />
              <InvoiceList group="Processed" link={nav.invoices} data={processedInvoices} />
              <InvoiceList group="Overdue" link={nav.invoices} data={overDueInvoices} />
            </>
          )
        }
      </section>
    </>
  );
};

export default InvoiceSummary;
