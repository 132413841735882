import React from 'react'
import styles from "./style.module.scss"

const Spinner = () => (
    <div className={styles.container}>
        <div className={styles.contentBox}>
            <div className={styles.loader}>
            </div>
            <p>Please wait...</p>
        </div>
    </div>
);

export default Spinner;