import React, { useState } from 'react';
import moment from 'moment';
import styles from './index.module.scss';
import { ForwardIcon } from '../../svg';

import CardTag from '../cardTag';
import Modal from 'react-modal';

const QuoteCard = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [terms, setTerms] = useState(false);
  const quoteData = props.data;
  const { invoiceDueDate, onQuoteAcceptanceOrRejection } = props;

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
  };
  const handleSubmit = () => {
    setTerms(true);
    onQuoteAcceptanceOrRejection(quoteData.id, true);
    setIsOpen(false);
  };

  const handleDecline = () => {
    onQuoteAcceptanceOrRejection(quoteData.id, false);
  };

  const financedProgress = (
    (quoteData.discountedValue / quoteData.invoiceValue) *
    100
  ).toFixed(2);

  return (
    <div className={styles.card} style={{ width: '40%' }}>
      <div className={`d-flex justify-content-between align-items-center ${styles.cardHeader}`}>
        <p>{quoteData.financierName}</p>
        <CardTag type={quoteData.status} text={quoteData.status} />
      </div>

      <div style={{ padding: 20 }}>
        <div className="d-flex justify-content-between">
          <p style={{ margin: 0 }}>Annual effective rate</p>
          <p style={{ margin: 0 }}>+ {quoteData.commission}% Management fee</p>
        </div>
        <p style={{ fontSize: 100, fontWeight: 100, margin: 0 }}>{quoteData.annualInterestRate}%</p>

        <div className="d-flex justify-content-between">
          <div>
            <p style={{ margin: 0 }}>Quote Value</p>
            <h2>{`${quoteData.currency} ${quoteData.discountedValue.toLocaleString()}`}</h2>
          </div>
          <div>
            <p style={{ margin: 0 }}>Total Invoice Value</p>
            <h2 style={{ textAlign: 'right' }}>{quoteData.currency} {quoteData.invoiceValue.toLocaleString()}</h2>
          </div>
        </div>

        <div className="d-flex align-items-center" style={{ backgroundColor: '#E8ECF3', height: 12, borderRadius: 4.5, margin: '20px 0 30px' }}>
          <div style={{ backgroundColor: '#EA3323', width: `${financedProgress}%`, height: 10, borderRadius: 4.5 }} />
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div style={{ flexGrow: 1, width: '33.3%' }}>
            <p style={{ margin: 0 }}>Quote expiry Date :</p>
            <p>{moment.utc(quoteData.expiryDate).format('ll')}</p>
          </div>
          <div style={{ textAlign: 'center'}}>
            <ForwardIcon />
          </div>
          <div style={{ flexGrow: 1, width: '33.3%' }}>
            <div style={{ marginLeft: 'auto', width: 'fit-content' }}>
              <p style={{ margin: 0 }}>Due Date :</p>
              <p>{moment(invoiceDueDate).format('ll')}</p>
            </div>
          </div>
        </div>
      </div>

      {quoteData.status === 'New' && (
        <div className={styles.btnContainer}>
          <button onClick={()=> setIsOpen(true)}>Accept</button>
          <button onClick={handleDecline} style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.15)' }}>
            Decline
          </button>
        </div>
      )}

      <Modal
        isOpen={isOpen}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Accept Financing">
        <div className={styles.modelContent}>
          <div style={{ justifyContent: 'space-between', display: 'flex' }}>
            <h1 style={{ margin: 40 }}>Accept Financing</h1>
            <button onClick={() => setIsOpen(false)}>X</button>
          </div>
          <p className={styles.modelInput}>{quoteData.financierName}</p>
          <p className={styles.modelInput}>Accept financing from {quoteData.financierName}</p>
          <div className={styles.modelInput} style={{ height: 300, overflow: 'auto' }}>
            <h2>Term & Conditions</h2>
            <p>
              What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting
              industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
              when an unknown printer took a galley of type and scrambled it to make a type specimen
              book it has? Lorem Ipsum has been the industry's standard dummy text ever since the
              1500s when an unknown printer took a galley of type and scrambled it to make a type
              specimen book. It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <p>
              What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting
              industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
              when an unknown printer took a galley of type and scrambled it to make a type specimen
              book it has? Lorem Ipsum has been the industry's standard dummy text ever since the
              1500s when an unknown printer took a galley of type and scrambled it to make a type
              specimen book. It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40 }}>
            <div className={styles.formCheck}>
              <input type="checkbox" id="tc1" checked={terms} onChange={() => setTerms(!terms)} />
              <label htmlFor="tc1">I accept the Terms & Conditions</label>
            </div>
            <button onClick={handleSubmit} className={styles.btn}>
              Accept & close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default QuoteCard;
