import React from 'react';
import CardTag from '../cardTag';
import styles from './index.module.scss';
import { MessageIcon } from '../../svg';
import placeHolderLogo from '../../../assets/img/place-holder-image.png';
import { pathOr } from 'ramda';
import { getCurrency } from '../../../utils';
import { Link } from 'react-router-dom';

interface IProfileCard {
    link?: string,
    className?: string,
    style?: {[key: string]: string | number},
    companyProfile: {[key: string]: any}
}

const ProfileCard = ({ companyProfile, link, className = '', style }: IProfileCard) => {
  const {
    name,
    registrationNumber,
    contactEmail,
    address,
    isActivated,
    financeDetails,
    companyLogo,
  } = companyProfile;

  // const shouldShowTag = !isActivated

  return (
    <div className={`${styles.card} ${className}`} style={style}>
      <div
        className={`d-flex justify-content-between align-items-center ${styles.cardHeader}`}>
        <p>{name}</p>
        {/* {shouldShowTag && <CardTag type="Rejected" text="Inactive" />} */}
      </div>

      <div
        className={`d-flex align-items-center ${styles.cardProfile}`}>
        <div className="d-flex justify-content">
          <img
            className="flex-grow-1"
            style={{ width: '100px' }}
            src={pathOr(placeHolderLogo, ['filePath'], companyLogo)}
            alt="company sample logo"
          />
        </div>
        <div style={{ paddingLeft: 10 }}>
          <p>{registrationNumber}</p>
          <p
            style={{ wordBreak: 'break-all' }}
            >{contactEmail}</p>
          <p>{address}</p>
        </div>
      </div>

      <div className={styles.cardDetails}>
        <h4>Total Financing allocated</h4>
        <p>
          {financeDetails && getCurrency(financeDetails.currency)}{' '}
          {financeDetails && financeDetails.financedValue.toLocaleString()}
        </p>
      </div>

      <div className={`d-flex justify-content-between align-items-center ${styles.cardFooter}`}>
        {link && (
          <Link className={`flex-grow-1 ${styles.supplierLink}`} to={link}>
            View Supplier Details
          </Link>
        )}
        <div className={styles.message}>
          <MessageIcon />
          <p className={styles.messageBadge}>0</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
