import React from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

interface ModuleInfoCardType {
  details: {
    name: string;
    link: string;
    count: number;
    linkText: string;
  };
}

function ModuleInfoCard({ details: { name, link, count, linkText } }: ModuleInfoCardType) {
  return (
    <div className={styles.card}>
      <div className={styles.infoBlock}>
        <div className={styles.count}>{count}</div>
        <div className={styles.name}>{name}</div>
      </div>
      <div className={styles.linkBlock}>
        <Link to={link}>{linkText}</Link>
      </div>
    </div>
  );
}

export default ModuleInfoCard;
