import React, { useState } from 'react';
import { map, pathOr, sum } from 'ramda';
import ProfileCard from '../../../components/cards/profilecard';
import { getCurrency } from '../../../utils';
import styles from './style.module.scss';

interface IInvoiceListType {
  status: string;
  data: any;
  link: string;
}

function getTotalAmount(listOfCompanyDetails: any) {
  if (listOfCompanyDetails.length <= 0) {
    return '0';
  } else {
    const currency = listOfCompanyDetails[0].financeDetails.currency;
    const financedValues = map<number, number>(
      pathOr<number>(0, ['financeDetails', 'financedValue']),
      listOfCompanyDetails
    );
    const total = sum(financedValues);
    return `${getCurrency(currency)} ${total.toLocaleString()}`;
  }
}

const InvoiceList = ({ data, link, status }: IInvoiceListType) => {
  const [isShowingAll, setIsShowingAll] = useState(false);

  const toggleIsShowingAll = () => {
    setIsShowingAll((previousValue) => !previousValue);
  };

  const buttonText = isShowingAll ? 'View less' : 'View all';

  const ListInvoice = data.map((el: any, index: any) => {
    return (
      <ProfileCard
        className={!isShowingAll ? styles.card : ''}
        key={`${status}-inCard-${el.id}-${index}`}
        link={`${link}/${el.id}`}
        companyProfile={el}
      />
    );
  });

  return (
    <>
      <section
        className="section-heading d-flex justify-content-between align-items-center"
        style={{ marginRight: 62 }}>
        <p className={styles.invoiceTitle}>
          {status} - {getTotalAmount(data)}
        </p>
        <button onClick={toggleIsShowingAll} className={styles.btnStyle}>
          {buttonText}
        </button>
      </section>
      <div className={styles.cardContainerLayout}>
        <div className={isShowingAll ? styles.cardContainerAll : styles.cardContainer}>
          {ListInvoice}
        </div>
      </div>
    </>
  );
};

export default InvoiceList;
