import React, { useEffect, useReducer, useContext, useState } from 'react';
import Modal from 'react-modal';
import FormCard from '../../../components/cards/form';
import UploadDocCard from '../../../components/cards/uploadDoc';
import InvoiceForm from './invoiceForm';
import { useParams, useHistory } from 'react-router-dom';
import Spinner from '../../../components/loader/Spinner';
import { UserContext } from '../../../App';
import styles from './viewInvoice.module.scss';
import { pathProvider } from '../../../constants';
import ErrorAlert from '../../../components/error';
import SuccessAlert from '../../../components/success';
import { format } from 'date-fns';
import moment from 'moment';
import {
  sendQuote,
  confirmQuotePayment,
  getIfInvoiceQuotedByFinancier,
  extendQuotedInvoiceDate,
  getInvoiceDetail,
  getInvoiceQuotes,
} from '../../../api';

const endOfWeek = () => {
  let date = new Date();
  const lastday = date.getDate() - date.getDay() + 5;
  date = new Date(date.setDate(lastday));

  return date;
};

function ViewInvoiceFinancier() {
  const { id }: any = useParams();
  const { role, currency } = useContext(UserContext);
  const nav = pathProvider(role);

  const [formData, setFormData] = useReducer((a: any, c: any) => ({ ...a, ...c }), {
    currency,
  });
  const [modalFormData, setModalFormData] = useReducer((a: any, c: any) => ({ ...a, ...c }), {
    currency,
  });

  const [isOpen, setIsOpen] = useState(false);

  const [extendedDateQ, setExtendDateQ] = useState(new Date());

  const [totalAmount, setTotalAmount] = useState(0);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [extendQuoteDate, setExtendQuote] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [annualRate, setAnnualRate] = useState(10.0);
  const [discountedVal, setDiscountVal] = useState(0.0);
  const [dueDate, setDueDate] = useState(new Date());
  const [dueDateQ, setDueDateQ] = useState(endOfWeek());

  const [bankRef, setBankRef] = useState('');

  const history = useHistory();

  const changeBankRef = (event: { target: HTMLInputElement }) => {
    handleChange(event);
    setBankRef(event.target.value);
  };

  const invoiceStatus = formData?.invoice?.status
  const isApproved = invoiceStatus === 'ApprovedForDiscounting';
  const isQuoted = invoiceStatus === 'InvoiceQuoted';
  const isAccepted = invoiceStatus === 'InvoiceQuoteAccepted';
  const shouldGetQuotes = invoiceStatus && invoiceStatus !== 'New' && invoiceStatus !== 'OptedInForDiscounting'

  useEffect(() => {
    getInvoiceDetail(id)
      .then((res) => setFormData(res.data))
      .catch((err) => console.log('err', err));
  }, [id]);

  useEffect(() => {
    if (shouldGetQuotes) {
      getInvoiceQuotes(id)
        .then((res) => {
          const date = new Date(res.data[0]?.expiryDate);
          if (date) {
            date.setDate(date.getDate() + (7 + 5 - date.getDay() - 1) % 7 + 1);
            setExtendDateQ(date);
          }
        })
        .catch((err) => console.log('err', err));
    }
  }, [id, shouldGetQuotes]);

  useEffect(() => {
    if (isQuoted) {
      getIfInvoiceQuotedByFinancier(id)
        .then((res) => setExtendQuote(true))
        .catch((error: any) => {});
    }
  }, [id, isQuoted]);

  useEffect(() => {
    if (isQuoted || isApproved) {
      setDueDate(new Date(formData.invoice.dueDate));
    }
  }, [isQuoted, isApproved, formData]);

  useEffect(() => {
    let errorTime: NodeJS.Timeout, successTime: NodeJS.Timeout;
    if (isError) {
      errorTime = setTimeout(() => {
        setError(false);
      }, 1000);
    }
    if (isSuccess) {
      successTime = setTimeout(() => {
        setSuccess(false);
      }, 1000);
    }
    return () => {
      clearTimeout(errorTime);
      clearTimeout(successTime);
    };
  }, [isError, isSuccess]);

  const calculateInvoice = (event: { target: HTMLInputElement }) => {
    // handleChange(event);
    // setDueDateQ(new Date(event.target.value));
  };

  useEffect(() => {
    if (formData.invoice && moment(dueDate) > moment() && annualRate > 0.0) {
      const currDate = moment();
      const duedateL = moment(dueDate);

      const diff = duedateL.diff(currDate, 'days');

      const invoiceValue = formData.invoice.value.pretaxValue + formData.invoice.value.taxValue;

      const totalInterestRate = (formData.config.commissionPercentage + annualRate) / 365;
      const totalInterest = totalInterestRate * diff;

      const calculatedVal = invoiceValue / (1 + totalInterest / 100);

      const discountedValue = Math.round((calculatedVal + Number.EPSILON) * 100) / 100;
      setDiscountVal(discountedValue);
      setTotalAmount(Math.round(invoiceValue * 100) / 100);
    }
  }, [annualRate, dueDate, dueDateQ, formData]);

  const setInterestRate = (event: { target: HTMLInputElement }) => {
    handleChange(event);
    setAnnualRate(parseFloat(event.target.value));
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      padding: 0,
    },
  };

  const handleChange = (e: any): any => {
    let data: any = { ...modalFormData };
    data[e.target.id] = e.target.value.trim();
    setModalFormData(data);
  };

  const handleSubmit = () => {
    setIsOpen(false);
    setLoading(true);
    const formattedDate = format(dueDateQ, 'yyyy-MM-dd');
    const interestRate = annualRate;
    const id = formData.invoice.id;
    sendQuote(id, formattedDate, interestRate)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setSuccessMessage('Quote sent successfully !');
        setTimeout(() => history.push(nav.invoices), 2500);
      })
      .catch((error: any) => {
        setLoading(false);
        setError(true);
        if (error.response) {
        }
      });
  };

  const handleExtendQuoteDate = () => {
    setLoading(true);
    const formattedDate = format(extendedDateQ, 'yyyy-MM-dd');
    const id = formData.invoice.id;
    extendQuotedInvoiceDate(id, formattedDate)
      .then(() => {
        setLoading(false);
        setSuccessMessage('Quote Validity Extended By a Week!');
        setSuccess(true);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response) {
          setError(true);
        }
      });
  };
  const confirmQuote = () => {
    setLoading(true);

    const id = formData.invoice.id;
    const ref1 = bankRef;
    confirmQuotePayment(id, ref1, formData.file)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setSuccessMessage('Invoice updated!');
        setTimeout(() => history.push(nav.invoices), 2500);
      })
      .catch((error: any) => {
        setLoading(false);
        setError(true);
        if (error.response) {
          // setErrorMessage()
        }
      });
  };

  const canSendQuote = (isApproved || isQuoted) && !extendQuoteDate;
  const canExtendQuoteDate = isQuoted && extendQuoteDate;
  const canConfirmTransaction = isAccepted;

  return (
    <div style={{ marginRight: 32 }}>
      {isLoading && <Spinner />}

      {isError && <ErrorAlert message={errorMessage} />}

      {isSuccess && <SuccessAlert message={successMessage} />}

      <section className="page-heading">
        Dashboard {'>'} Invoices {'>'} Ref No: {formData.invoice?.id}
      </section>

      <section className="d-flex" style={{ gap: 20, flexWrap: 'wrap-reverse' }}>
        <div style={{ flexGrow: 2 }}>
          <FormCard>
            <div className="form-heading border-bottom-1">Ref No: <span style={{ fontWeight: 'bold' }}>{formData.invoice?.id}</span></div>
            <InvoiceForm formData={formData} />
          </FormCard>
        </div>

        <div style={{ width: 300, flexGrow: 2 }}>
          <UploadDocCard
            isFile={true}
            onUpload={setFormData}
            disabled={true}
            fileData={formData.invoice?.attachment || {}}
            id="supplierLogo"
          />

          {canSendQuote && (
            <div className={styles.card}>
              <div className={`${styles.cardHead} border-bottom-1`}>
                <p>Express interest</p>
              </div>
              <div className={styles.cardFoot}>
                <button onClick={() => setIsOpen(true)} className={styles.cardButton}>
                  Send a quote
                </button>
              </div>
            </div>
          )}

          {canExtendQuoteDate && (
            <div className={styles.card}>
              <div className={`${styles.cardHead} border-bottom-1`}>
                <p>Extend Quote</p>
              </div>
              <div className={styles.cardFoot}>
                <button onClick={() => handleExtendQuoteDate()}>Extend Quote Date</button>
              </div>
            </div>
          )}

          {canConfirmTransaction && (
            <div className={styles.card}>
              <div className={`${styles.cardHead} border-bottom-1`}>
                <p>Confirm transaction details</p>
              </div>
              <div className="form-group" style={{ padding: '10px 25px' }}>
                <label className="form-label" htmlFor="bankRef">
                  Enter the bank reference number (optional)
                </label>
                <input
                  style={{ borderColor: 'black' }}
                  onChange={(e) => changeBankRef(e)}
                  type="text"
                  id="bankRef"
                  value={bankRef || ''}
                />
              </div>
              <div className={styles.cardFoot}>
                <button onClick={() => confirmQuote()}>Confirm Payment</button>
              </div>
            </div>
          )}
        </div>

        <Modal
          isOpen={isOpen}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Accept Financing">
          <div className={styles.modelContent}>
            <div className="d-flex justify-content-between border-bottom-1">
              <h1 style={{ margin: 0 }}>Make an offer</h1>
              <button style={{ fontSize: 32 }} onClick={() => setIsOpen(false)}>
                &times;
              </button>
            </div>
            <div style={{ marginTop: 10 }}>
              <p>Your quote will be sent to the buyer then shared to supplier</p>
            </div>
            <div className="d-flex justify-content-between">
              <div style={{ width: '45%' }}>
                <div className={`form-group`}>
                  <label className="form-label" htmlFor="financialRate">
                    Annual financing rate ( % )
                  </label>
                  <input
                    onChange={(e) => setInterestRate(e)}
                    type="number"
                    value={annualRate || ''}
                    id="financialRate"
                  />
                </div>
                <div className={`form-group`}>
                  <label className="form-label" htmlFor="quoteValue">
                    Quote Value ({ currency })
                  </label>
                  <input
                    readOnly
                    disabled
                    onChange={(e) => handleChange(e)}
                    type="number"
                    value={discountedVal}
                    id="quoteValue"
                  />
                </div>
                <div className={`form-group`}>
                  <label className="form-label" htmlFor="quoteExpireDate">
                    Quote Expiry Date
                  </label>
                  <input
                    value={format(dueDateQ, 'yyyy-MM-dd')}
                    readOnly
                    disabled
                    id="quoteExpireDate"
                    onChange={(e) => calculateInvoice(e)}
                    type="date"
                  />
                </div>
              </div>
              <div style={{ width: '45%' }}>
                <div className={`form-group`}>
                  <label className="form-label" htmlFor="effectiveRate">
                    Effective rate ( % )
                  </label>
                  <input
                    readOnly
                    disabled
                    onChange={(e) => handleChange(e)}
                    value={formData.config ? formData.config.commissionPercentage + annualRate : ''}
                    type="number"
                    id="effectiveRate"
                  />
                </div>
                <div className={`form-group`}>
                  <label className="form-label" htmlFor="totalInvoiceValue">
                    Total Invoice Value ({ currency })
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="number"
                    value={totalAmount}
                    readOnly
                    disabled
                    id="totalInvoiceValue"
                  />
                </div>
                <div className={`form-group`}>
                  <label className="form-label" htmlFor="dueDate">
                    Invoice Due Date
                  </label>
                  <input
                    readOnly
                    disabled
                    value={format(dueDate, 'yyyy-MM-dd')}
                    type="date"
                    id="dueDate"
                  />
                </div>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <button onClick={handleSubmit} className={styles.btn}>
                Send a quote
              </button>
            </div>
          </div>
        </Modal>
      </section>
    </div>
  );
}

export default ViewInvoiceFinancier;
