import React from 'react'
import Card from '../../components/cards/headingwithComponent'
import Footer from '../../components/footer'
import commingsoonImg from '../../assets/img/comming soon.jpg'
import logo from '../../assets/img/sc-logo.png';
import styles from './style.module.scss'
const CommingSoon = () => {
    return(
                <div>
                    <div className={styles.header}>
                {/* <div style={{display:"flex", justifyContent:'flex-end', padding: '30px 60px'}} onClick={signOut}>
                    <SignOutIcon />
                    <span>Sign out</span>
                </div> */}
                <div style={{paddingLeft:158}}>
                    <img src={logo} alt="sc logo" width="224px" height="88px"/>
                    {/* <div className="row" style={{padding:'100px 158px 0 0', marginRight:0}}>
                    <div className="col-4">
                        <h1>Supplier <br/>Onboarding Process</h1>
                    </div>
                    <div className="col-8">
                        <p style={{color: 'rgba(4, 12, 71, 0.49)', lineHeight:'26px', fontSize:22}}>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                    </div> */}
                </div>
            </div>
            <div style={{display:"flex", alignItems:'center', justifyContent:'center', height:'55vh', backgroundColor:'rgba(0, 0, 0, 0.12)'}}>
                {/* <img src={commingsoonImg} alt="comming soon" width="300" height="350"/> */}
                <p style={{fontSize: 70, color: "black", fontWeight: 700}}>Coming Soon</p>
            </div>
            <Footer />
        </div>
    )
}

export default CommingSoon;