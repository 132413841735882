import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TruckIcon } from '../../../components/svg';
import SearchFormInput from '../../../components/form/search';
import Form from '../../../components/form';
import InvoiceList from './invoiceList';
// import InvoiceResultList from './invoiceResultList';
import { getAllSuppliers, searchSupplier } from '../../../api';
import Spinner from '../../../components/loader/Spinner';
import { pathProvider } from '../../../constants';
import { UserContext } from '../../../App';
import { pathOr, map, filter, propEq } from 'ramda';
import styles from './style.module.scss';
moment().locale('en-in');

interface data {
  [key: string]: any;
}

enum Status {
  NEW = 'new',
  ACTIVE = 'active',
}

const isActivated = propEq<string>('isActivated', true);
const isNotActivated = propEq<string>('isActivated', false);

const getCompanyProfile = (companyDetails: any) => {
  return {
    id: pathOr('', ['companyDetails', 'id'], companyDetails),
    isActivated: pathOr(false, ['companyDetails', 'isActivated'], companyDetails),
    name: pathOr('', ['companyDetails', 'name'], companyDetails),
    registrationNumber: pathOr('', ['companyDetails', 'registrationNumber'], companyDetails),
    contactEmail: pathOr('', ['companyDetails', 'contactDetails', 'email'], companyDetails),
    address: pathOr('', ['companyDetails', 'contactDetails', 'billingAddress'], companyDetails),
    financeDetails: {
      financedValue: pathOr(0, ['supplierFinancedSummary', 'totalInvoiceValue'], companyDetails),
      currency: pathOr('NGN', ['companyDetails', 'defaultCurrency'], companyDetails),
    },
    companyLogo: pathOr<object>({}, ['companyDetails', 'companyLogo'], companyDetails),
  };
};

const ViewAllSupplier = () => {
  const [data, setData] = useState<data>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearch, setIsSearch] = useState(false);

  const { role } = useContext(UserContext);
  const nav = pathProvider(role);

  const activeSuppliers: data = filter(isActivated, data);
  const inactiveSuppliers: data = filter(isNotActivated, data);

  const fetchSupplierData = (): Promise<any> => {
    return searchQuery ? searchSupplier(searchQuery) : getAllSuppliers();
  };

  const getSupplierData = () => {
    setLoading(true);
    fetchSupplierData()
    .then(res => {
      setData(map(getCompanyProfile, res.data));
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  useEffect(() => {
    getSupplierData()
  }, []);

  useEffect(() => {
    if (!searchQuery && isSearch) {
      setIsSearch(false)
      getSupplierData()
    }
  }, [searchQuery]);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSearch(!!searchQuery)
    getSupplierData();
  };

  return (
    <>
      {loading && <Spinner />}

      <section className="page-heading">Dashboard {'>'} Suppliers</section>

      <section
        className={`d-flex justify-content-between align-items-center ${styles.searchSection}`}>
        <div className="flex-grow-1">
          <Form onSubmit={handleSearch}>
            <SearchFormInput
              className={styles.searchFormInput}
              placeholder="Search for supplier by Name, Id"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </Form>
        </div>
        <Link className="d-flex align-items-center text" to={nav.addSupplier}>
          <TruckIcon />
          <span>Add Supplier</span>
        </Link>
      </section>

      {
        isSearch ? (
          <InvoiceList status="Search Result" link={nav.suppliers} data={data} />
        ) : (
          <>
            <InvoiceList status={Status.ACTIVE} link={nav.suppliers} data={activeSuppliers} />
            
            <InvoiceList status={Status.NEW} link={nav.suppliers} data={inactiveSuppliers} />
          </>
        )
      }

    </>
  );
};

export default ViewAllSupplier;
