import React from 'react'
import styles from "./success.module.scss"
import {SuccessIcon} from '../svg';

interface successMessage {
    message: string
}
const SuccessAlert = ({message}:successMessage) => (
    <div className={styles.container}>
        <div className={styles.contentBox}>
        <span>
            <SuccessIcon width="25px" height="25px"/>
            <p>{message}</p>
        </span>
        </div>
    </div>
);

export default SuccessAlert;