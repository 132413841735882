import axiosInstance from "./interceptor";
import { financierInvoiceData, invoiceSummaryData } from './seed';
const loginURL = "/auth/login"
const logoutURL = "/auth/logout"

const addSupplierURL = '/company/add-supplier'
const getSuppliersURL = '/company/suppliers'
const getSupplierCompanyDetailsURL = '/company/supplier-details'
const getAllSuppliersURL = '/company/all-suppliers'
const getCompanyDetailsUsingActivationCodeURL = '/company/supplier/on-boarding'
const completeSupplierOnboardingURL = '/company/supplier/complete-boarding'
const getClientSupplierDetailsURL = '/company/client-supplier-details'
const activateSupplierURL = '/company/supplier/activate-supplier'
const addUserURL = '/company/add-user'
const getAllUsersURL = '/company/users'
const searchSupplierURL =  '/company/suppliers/search'
const searchSupplierInvoiceURL =  '/invoice/supplier/search'

const addInvoiceURL = '/invoice/add-invoice'
const getInvoiceURL = '/invoice/details';
const clientInvoiceSummaryURL = '/invoice/client-summary'
const financierInvoiceSummaryURL = '/invoice/financier-summary'
const ifInvoiceQuotedByFinancier = '/invoice/if-quoted-by-financier'
const financierInvoiceSearchURL = '/invoice/financier/search'

const extendQuoteDate = '/invoice/extend-quote-date'

const supplierInvoiceSummaryURL = '/invoice/supplier-summary'
const searchURL = '/invoice/search'
const optInForDiscountingURL = '/invoice/opt-in-for-discounting'
const approveDiscountingURL = '/invoice/approve-for-discounting'
const invoiceQuotesURL = '/invoice/quotes'
const invoiceQuoteURL = '/invoice/quote'
const acceptInvoiceQuoteURL = '/invoice/accept-quote'
const rejectInvoiceQuoteURL = '/invoice/reject-quote'
const confirmQuotePaymentURL = '/invoice/discounted'
const markInvoiceProcessedURL = '/invoice/paid'

const userFinanceInfoURL = '/user/financeinfo'
const userFinancierCountURL = '/user/financier-count'
const userFinanceChartURL = '/user/finance-chart'
const userYearlyFinanceURL = '/user/yearly-financing'



export function signIn(credential: { username: string; password: string }) {
  
  return axiosInstance.post(loginURL, {
    email: credential.username,
    password: credential.password
  }).then((response:any) => {
    console.log("login res==>", response)
    return {authToken: response.data.authToken, role: response.data.role, defaultCurrency: response.data.defaultCurrency, name: response.data.name}
  }).catch(err => Error("error"))
      // return Promise.resolve({authToken: credential})

}


export function logOut() {
  return axiosInstance.post(logoutURL)
}

export function addSupplier(formData: any) {
  return axiosInstance.post(addSupplierURL, formData)
}

export function getSupplier() {
  return axiosInstance.get(getSuppliersURL)
}

export function addInvoice (formData: any) {
  return axiosInstance.post(addInvoiceURL, formData)
}

export function extendQuotedInvoiceDate(invoiceId:string, quoteExpiryDate : any){
  return axiosInstance.post(extendQuoteDate, {invoiceId, quoteExpiryDate})
}

export function getInvoiceSummary() {
  //console.log("testjson", Promise.resolve({data:{status:true, data:invoiceSummaryData}}))
  return Promise.resolve({data:{status:true, data:invoiceSummaryData}})
}

export function getInvoiceDetail(invoiceId:string){
  return axiosInstance.get(`${getInvoiceURL}/${invoiceId}`)
}

export function getIfInvoiceQuotedByFinancier(invoiceId:string){
  return axiosInstance.get(`${ifInvoiceQuotedByFinancier}/${invoiceId}`)
}

export function getClientInvoiceSummary() {
  return axiosInstance.get(clientInvoiceSummaryURL).then((response:any) => {
    return {data:{status:true, data:response.data}}})
}

export function getSupplierInvoiceSummary() {
  return axiosInstance.get(supplierInvoiceSummaryURL).then((response:any) => {
    return {data:{status:true, data:response.data}}})
}

export function addUser(formData: any) {
  return axiosInstance.post(addUserURL, formData)
}

export function getAllUsers() {
  return axiosInstance.get(getAllUsersURL)
}

export function search(data: string) {
  return axiosInstance.get(searchURL, {params:{input:data}})
}

export function optInForDiscounting(invoiceId: Number) {
  return axiosInstance.post(`${optInForDiscountingURL}/${invoiceId}`)
}

export function approveDiscounting(invoiceId: Number) {
  return axiosInstance.post(`${approveDiscountingURL}/${invoiceId}`)
}

export function getInvoiceQuotes(invoiceId: Number) {
  return axiosInstance.get(`${invoiceQuotesURL}/${invoiceId}`)
}

export function acceptInvoiceQuote(quoteId: Number) {
  return axiosInstance.post(`${acceptInvoiceQuoteURL}/${quoteId}`)
}

export function rejectInvoiceQuote(quoteId: Number) {
  return axiosInstance.post(`${rejectInvoiceQuoteURL}/${quoteId}`)
}


export function getSupplierCompanyDetails() {
  return axiosInstance.get(getSupplierCompanyDetailsURL)
}

export function getFinancierInvoices (){
  //return Promise.resolve({data: {status:true, data: financierInvoiceData}})
  return axiosInstance.get(financierInvoiceSummaryURL).then((response:any) => {
    return {data:{status:true, data:response.data}}})
}

export function getAllSuppliers() {
  return axiosInstance.get(getAllSuppliersURL)
}

export function getCompanyDetailsUsingActivationCode(token: any) {
  return axiosInstance.get(`${getCompanyDetailsUsingActivationCodeURL}/${token}`)
}

export function completeSupplierOnboarding(formdata: any){
  return axiosInstance.post(completeSupplierOnboardingURL, formdata)
}

export function getClientSupplierDetails(supplierId: any) {
  return axiosInstance.get(`${getClientSupplierDetailsURL}/${supplierId}`)
}

export function activateSupplier(supplierId: any) {
  return axiosInstance.post(`${activateSupplierURL}/${supplierId}`)
}

export function sendQuote(invoiceId: any, quoteExpiryDate: any, annualInterestRate: any){
  return axiosInstance.post(invoiceQuoteURL, {invoiceId,
    quoteExpiryDate, annualInterestRate});
}

export function confirmQuotePayment(invoiceId: number, transactionReference: string, attachment: any){
  return axiosInstance.post(confirmQuotePaymentURL, {invoiceId,
    transactionReference});
}

export function markInvoiceProcessed(invoiceId: any){
  return axiosInstance.post(markInvoiceProcessedURL, {invoiceId})
}

export function getUserFinanceInfo() {
  return axiosInstance.get(userFinanceInfoURL)
}

export function getUserFinancierCount() {
  return axiosInstance.get(userFinancierCountURL)
}

export function getUserFinanceChart() {
  return axiosInstance.get(userFinanceChartURL)
}

export function getUserYearlyFinance() {
  return axiosInstance.get(userYearlyFinanceURL)
}

export function searchSupplier(input: string) {
  return axiosInstance.get(`${searchSupplierURL}?input=${input}`)
}

export function financierInvoiceSearch(input: string) {
  return axiosInstance.get(`${financierInvoiceSearchURL}?input=${input}`)
}

export function searchSupplierInvoice(data: string) {
  return axiosInstance.get(searchSupplierInvoiceURL, {params:{input:data}})
}
