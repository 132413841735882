import React, { useState, useContext } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import styles from "./style.module.scss";
import logo from "../../assets/img/logo.png";
import Footer from "../../components/footer";
import Form from "../../components/form";
import { clientAuthRole, clientAuthTokenKey, paths, pathProvider, clientCurrency, clientName } from "../../constants";
import { isUserAuthenticated } from "../../utils";
import { signIn } from "../../api";
import Spinner from "../../components/loader/Spinner";
import {UserContext} from '../../App';

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [visibility1, setVisible] = useState("none")

  const history = useHistory();

  const userContext = useContext(UserContext);
  const nav = pathProvider(userContext.role);

  if (isUserAuthenticated() && userContext.role) {
    switch (userContext.role) {
      case 'client':
      case 'supplier':
        return <Redirect to={nav.dashboard} />;
      default:
        return <Redirect to={nav.invoices} />;
    }
  }

  const handleRole = (role:role) => {
    localStorage.setItem(clientAuthRole, role|| '');
    userContext.setRole(role);
  }

  const handleCurrency = (currency:string) => {
    localStorage.setItem(clientCurrency, currency);
    userContext.setCurrency(currency)
  }

  const handleName = (name: string) => {
    localStorage.setItem(clientName, name);
    userContext.setName(name)
  }

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (errorMsg) setErrorMsg("");
    if (userName && password) {
      setLoading(true);
      const credential = {
        username: userName,
        password: password,
      };
      signIn(credential)
        .then(({ authToken,  role, defaultCurrency, name }: any) => {
          localStorage.setItem(clientAuthTokenKey, authToken);
          //console.log(authToken)
          if(authToken===undefined){
          setVisible("block")
          //console.log("visible")
          }
          handleRole(role);
          handleCurrency(defaultCurrency)
          handleName(name)
          setLoading(false);
          
        })
        .catch((error: any) => {
          if (error.response) {
            setLoading(false);
            setErrorMsg("Invalid credentials.");
            
          }
        });
    } else {
      setLoading(false);
      setErrorMsg("Please fill in all fields.");
    }
  };
  return (
    <div className={styles.loginBox}>
      {loading && <Spinner />}
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.loginContainer}>
        <div className={styles.rowBox}>
          <div className="row">
            <div className="col-6">
              <div className={styles.descBlock}>
                <h1 className={styles.descBlockHeading}>Factoring Service</h1>
                <p className={styles.descBlockDetails}>
                Factoring is one of the oldest forms of business financing. Once used mostly by large corporations, factoring is becoming more widespread. In a typical factoring arrangement, the client (you) makes a purchase from your supplier, collects the product or service and receives an invoice. The factor (the funding source) buys the right to collect on that invoice by agreeing to pay your supplier the invoice's face value less a discount. The factor pays a majority percentage of the face value immediately to your supplier and forwards the remainder (less the discount) to your supplier once you (the client) pays.
                </p>
              </div>
            </div>
            <div className={`col-6 ${styles.loginCol}`}>
              <div className={styles.loginBlock}>
                <div>
                  <h4 className={styles.loginBlockLabel}>Sign in</h4>
                  <p className={styles.loginBlockSubLabel}>
                    Please enter your details below to log in.
                  </p>
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <div className="form-group">
                      <label className="form-label" htmlFor="username">
                        Username
                      </label>
                      <input
                        type="email"
                        id="username"
                        value={userName}
                        onChange={(e) => setUserName(e.currentTarget.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        required
                      />
                    </div>
                    <div className="incorrect" style={{ display: visibility1 }} >
                    <p className={`btn ${styles.incorrect}`}>Incorrect Credentials</p>
                    </div>
                    <div className="form-actions">
                      <button
                        className={`btn ${styles.loginBtn}`}
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                  </Form>
                  <div>
                    <Link className="text-link" to="/">
                      Forgot my details
                    </Link>
                  </div>
                  {errorMsg ? (
                    <div className={styles.errorBlock}>
                      {/* {errorMsg} */}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
