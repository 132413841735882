import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./style.module.scss";
import logo from "../../assets/img/logo.png";
import SearchFormInput from '../../components/form/search';
import Form from '../../components/form';
import { SignOutIcon, FileIcon, NotificationIcon } from "../svg";
import { clearStorage } from "../../utils";
import { paths, pathProvider } from "../../constants";
import { UserContext } from '../../App'
import { logOut } from "../../api";

function Header() {
  const history = useHistory();
  const signOut = () => {
    logOut().then( () => {
      clearStorage();
      history.push(paths.login);
    }).catch(() => {
      clearStorage();
      history.push(paths.login);
    })
  };

  const [searchQuery, setSearchQuery] = useState('')


  const userContext = useContext(UserContext);
  const nav = pathProvider(userContext.role);
  const location = useLocation();
  const [isSearch, setIsSearch] = useState(false);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchQuery) {
      setIsSearch(true);
      switch (userContext.role) {
        case 'client':
        case 'financier':
          history.push({ pathname: nav.invoices, search: `?invoice=${searchQuery}` })
          break;
        case 'supplier':
          history.push({ pathname: nav.dashboard, search: `?invoice=${searchQuery}` })
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (!searchQuery && isSearch) {
      setIsSearch(false);
      switch (userContext.role) {
        case 'client':
        case 'financier':
          history.push({ pathname: nav.invoices })
          break;
        case 'supplier':
          history.push({ pathname: nav.dashboard })
          break;
        default:
          break;
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    const query = new URLSearchParams(location.search).get('invoice');
    setSearchQuery(query ?? '');
    setIsSearch(!!query);
  }, [location]);

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className="flex-grow-1">
        <Form onSubmit={handleSearch}>
          <SearchFormInput
            placeholder="Search Invoice"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </Form>
      </div>

      <div className={styles.menu}>
        { userContext.role === 'client' && (
          <div className={styles.menuItem} onClick={()=>history.push(nav.addInvoice)}>
            <FileIcon  />
            <span>Add&nbsp;Invoice</span>
          </div>
        )}
        <div className={styles.menuItem} onClick={signOut}>
          <NotificationIcon />
          <span>Notifications</span>
        </div>
        <div className={styles.menuItem} onClick={signOut}>
          <SignOutIcon />
          <span>Sign out</span>
        </div>
      </div>
    </header>
  );
}

export default Header;
