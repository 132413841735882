import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import InvoiceList from '../../invoices/invoiceSummary/invoiceList'
import { getSupplierInvoiceSummary, getSupplierCompanyDetails, searchSupplierInvoice } from '../../../api';
import Spinner from '../../../components/loader/Spinner';
import ProgressBar from '../../../components/progressBar';
import ProfileCard from '../../../components/cards/profilecard';
import DocumentCard from '../../../components/cards/documentCard';
import { InvoiceData } from '../../../interface';
import { pathOr, filter, includes, propSatisfies, __ } from 'ramda';
import { getCurrency } from '../../../utils';
import { pathProvider } from '../../../constants';
import { UserContext } from '../../../App';
import { useLocation } from "react-router-dom";
import styles from './index.module.scss';

moment().locale('en-in');

const openForDiscountSectionStatus = ['New', 'OptedInForDiscounting', 'ApprovedForDiscounting'];
const invoiceQuotedSectionStatus = ['InvoiceQuoted', 'InvoiceQuoteAccepted', 'InvoiceDiscounted'];

const SupplierInvoiceSummary = () => {
  const [data, setData] = useState<Array<InvoiceData>>([]);
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const location = useLocation();

  const [companyDetails, setCompanyDetails] = useState({});

  const openForDiscountSection = filter<InvoiceData>(
    propSatisfies(includes(__, openForDiscountSectionStatus), 'status'),
    data
  );
  const invoiceQuotedSection = filter<InvoiceData>(
    propSatisfies(includes(__, invoiceQuotedSectionStatus), 'status'),
    data
  );

  const { role, name } = useContext(UserContext);
  const nav = pathProvider(role);

  useEffect(() => {
    getSupplierCompanyDetails()
      .then((res) => {
        setCompanyDetails(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const fetchInvoiceData = (query: any): Promise<any> => {
    return query ? searchSupplierInvoice(query) : getSupplierInvoiceSummary();
  };

  const getInvoiceData = (query: any) => {
    setLoading(true);
    fetchInvoiceData(query)
    .then(res => {
      setData(res.data?.data || res.data);
      setLoading(false);
      setIsSearch(!!query)
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search).get('invoice');
    getInvoiceData(query);
  }, [location]);

  const companyProfile = {
    name: pathOr('', ['companyDetails', 'name'], companyDetails),
    registrationNumber: pathOr('', ['companyDetails', 'registrationNumber'], companyDetails),
    contactEmail: pathOr('', ['companyDetails', 'contactDetails', 'email'], companyDetails),
    address: pathOr('', ['companyDetails', 'contactDetails', 'billingAddress'], companyDetails),
    financeDetails: {
      financedValue: pathOr(0, ['supplierFinancedSummary', 'discountedValue'], companyDetails),
      currency: pathOr('NGN', ['companyDetails', 'defaultCurrency'], companyDetails),
    },
    companyLogo: pathOr({}, ['companyDetails', 'companyLogo'], companyDetails),
  };

  const companyDocuments = {
    bankStatement: pathOr('', ['companyDetails', 'bankStatement'], companyDetails),
    registrationCertificate: pathOr(
      '',
      ['companyDetails', 'registrationCertificate'],
      companyDetails
    ),
    signedPlatformAgreement: pathOr(
      '',
      ['companyDetails', 'signedPlatformAgreement'],
      companyDetails
    ),
  };

  const supplierFinancedSummary = {
    currency: pathOr('NGN', ['supplierFinancedSummary', 'currency'], companyDetails),
    discountedValue: pathOr(0, ['supplierFinancedSummary', 'discountedValue'], companyDetails),
    totalInvoiceValue: pathOr(0, ['supplierFinancedSummary', 'totalInvoiceValue'], companyDetails),
  };

  const financedProgress = supplierFinancedSummary.totalInvoiceValue
    ? (
        (supplierFinancedSummary.discountedValue / supplierFinancedSummary.totalInvoiceValue) *
        100
      ).toFixed(2)
    : 0;
  console.log('companyDetails', companyDetails);

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <main style={{ paddingRight: 50 }}>
          <section className="welcome-heading d-flex justify-content-between">
            <p>Welcome back { name },</p>
            <p style={{ fontSize: 20 }}>{`As of ${moment().format('LL')}`}</p>
          </section>

          <section className={styles.Section}>
            <div className="d-flex justify-content-between">
              <span>{companyProfile.name} Financing</span>
              <span>Total invoice value</span>
            </div>

            <ProgressBar bgcolor={'#EA3323'} completed={financedProgress} />
            <div className="d-flex justify-content-between" style={{ fontWeight: 700, fontSize: 39 }}>
              <span>
                {getCurrency(supplierFinancedSummary.currency)} {supplierFinancedSummary.discountedValue.toLocaleString()}
              </span>
              <span>
                {getCurrency(supplierFinancedSummary.currency)} {supplierFinancedSummary.totalInvoiceValue.toLocaleString()}
              </span>
            </div>
          </section>

          <section>
            {isSearch ? (
              <InvoiceList group="Search results" link={nav.invoices} data={data} />
            ) : (
              <>
                <InvoiceList group="Open for discount" link={nav.invoices} data={openForDiscountSection} />
                <InvoiceList group="Quoted" link={nav.invoices} data={invoiceQuotedSection} />
              </>
            )}
          </section>

          <section>
            <h3 className={`${styles.SectionHeader}`}>About Us</h3>
            <div className="d-flex gap-30">
              <ProfileCard companyProfile={companyProfile} style={{ maxWidth: '400px' }} />
              <DocumentCard companyDocuments={companyDocuments} data={{ cStyle: { width: '100%', maxWidth: '400px' } }} />
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default SupplierInvoiceSummary;
