import React, { useState, useContext } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useHistory } from "react-router-dom";

import { pathProvider } from '../../../constants'
import { UserContext } from '../../../App';
import FileUploadBlock from "../../../components/blocks/fileUpload";
import styles from "./SupplierForm.module.scss";


function SupplierForm({handleChange, formData, setFile}:any) {
  const [contact, setContact] = useState('234');
  const [representativeTel, setRepresentativeTel] = useState('234');
  const history = useHistory();
  const userContext = useContext(UserContext);
  const nav = pathProvider(userContext.role);

  return (
    <>
      <div className={`row ${styles.formSection}`}>
        <div className="col-12">
          <div className="form-group">
            <label className="form-label" htmlFor="name">
              Name:
            </label>
            <input
              type="text"
              id="name"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="rcNumber">
            RC Number:
            </label>
            <input
              type="text"
              id="rcNumber"
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="contact">
              Tel:
            </label>
            <PhoneInput
              value={contact}
              onChange={(phone)=> {
                const e = {
                  target: {
                    id: "contact",
                    value: phone
                  }
                }
                setContact(phone);
                handleChange(e);
              }}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="billingAddress">
              Registered Billing Address:
            </label>
            <textarea
              id="billingAddress"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="state">
              State:
            </label>
            <input
              type="text"
              id="state"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className={`row ${styles.formSection} ${styles.sectionDeco}`}>
        <div className="col-6">
        <div className="form-group">
            <label className="form-label" htmlFor="bankName">
              Bank Name:
            </label>
            <input
              type="text"
              id="bankName"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-6"></div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="acNumber">
              Account Number:
            </label>
            <input
              type="text"
              id="acNumber"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="acName">
              Account Name:
            </label>
            <input
              type="text"
              id="acName"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className={`row ${styles.formSection}`}>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="representativeName">
              Office representative name:
            </label>
            <input
              type="text"
              id="representativeName"
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="designation">
              Designation:
            </label>
            <input
              type="text"
              id="designation"
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="representativeTel">
              Tel:
            </label>
            <PhoneInput
              value={representativeTel}
              onChange={(phone)=> {
                const e = {
                  target: {
                    id: "representativeTel",
                    value: phone
                  }
                }
                setRepresentativeTel(phone);
                handleChange(e);
              }}            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="representativeEmail">
              Email:
            </label>
            <input
              type="email"
              id="representativeEmail"
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
      <div className={`row ${styles.formSection} ${styles.sectionDeco}`}>
        <div className={`col-12 ${styles.mb40}`}>
          <div className="form-group">
            <label className="form-label">
              Company registered certifications
            </label>
            <div style={{backgroundColor: '#fff'}}>
              <FileUploadBlock id='supplierCompanyCertificate' onUpload={(file: any)=>{setFile({"supplierCompanyCertificate":file})}} />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="form-label">
              Bank statement with company stamp (3 years)
            </label>
            <div style={{backgroundColor: '#fff'}}>
              <FileUploadBlock id='supplierCompanyStamp' onUpload={(file: any)=>{setFile({"supplierCompanyStamp":file})}} />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="form-label">
            Signed Platform Agreement 
            </label>
            <div style={{backgroundColor: '#fff'}}>
              <FileUploadBlock id='agreement' onUpload={(file: any)=>{setFile({"agreement":file})}}/>
            </div>
          </div>
        </div>
      </div>
      <div className={`row ${styles.formSection}`}>
        <div className={styles.buttonGroup}>
          <button type="submit" className={`${styles.btnRed}`}>Add Supplier</button>
          <button
            onClick={
              (e)=> {e.preventDefault();
              history.push(nav.suppliers)
            }}
            className={`${styles.btnGrey}`}
          >
            Cancel Supplier
          </button>
        </div>
      </div>
    </>
  );
}

export default SupplierForm;
