import React, { useEffect, useReducer, useState, useContext } from 'react';
import FormCard from '../../../components/cards/form';
import UploadDocCard from '../../../components/cards/uploadDoc';
import InvoiceForm from './invoiceForm';
import Form from '../../../components/form';
import Spinner from '../../../components/loader/Spinner';
import { addInvoice } from '../../../api';
import { useHistory } from 'react-router-dom';
import ErrorAlert from '../../../components/error';
import SuccessAlert from '../../../components/success';
import { UserContext } from '../../../App';
import { pathProvider } from '../../../constants';

interface FormData {
  pretax: string;
  tax: string;
  currency: string;
  invoiceNo: string;
  supplierId: string;
  issueDate: string;
  dueDate: string;
  file: object;
}

const formDataInit: FormData = {
  pretax: '',
  tax: '',
  currency: '',
  invoiceNo: '',
  supplierId: '',
  issueDate: '',
  dueDate: '',
  file: {},
};

const isValidateData = (obj: any): boolean => {
  for (let k in obj) {
    if (typeof obj[k] === 'string' && obj[k].trim().length < 1) {
      return false;
    }
  }
  return true;
};

const structureInvoiceData = ({
  pretax,
  tax,
  currency,
  invoiceNo,
  supplierId,
  issueDate,
  dueDate,
  file,
}: FormData) => {
  return {
    value: { pretaxValue: pretax, taxValue: tax, currency: currency },
    invoiceNumber: invoiceNo,
    supplierID: supplierId,
    issueDate: issueDate,
    dueDate: dueDate,
    attachment: file,
  };
};

function AddInvoice() {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [isSuccess, setSuccess] = useState(false);

  const history = useHistory();
  const { role, currency } = useContext(UserContext);
  const [formData, setFormData] = useReducer(
    (a: FormData, c: Partial<FormData>) => ({ ...a, ...c }),
    { ...formDataInit, currency }
  );
  const nav = pathProvider(role);

  useEffect(() => {
    let errorTime: NodeJS.Timeout, successTime: NodeJS.Timeout;
    if (isError) {
      errorTime = setTimeout(() => {
        setError(false);
      }, 1000);
    }
    if (isSuccess) {
      successTime = setTimeout(() => {
        setSuccess(false);
      }, 1000);
    }
    return () => {
      clearTimeout(errorTime);
      clearTimeout(successTime);
    };
  }, [isError, isSuccess]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (isValidateData(formData)) {
      addInvoice(structureInvoiceData(formData))
        .then(() => {
          setLoading(false);
          setSuccess(true);
          setTimeout(() => history.push(nav.invoices), 2500);
        })
        .catch((error: any) => {
          setLoading(false);
          if (error.response) {
            const { status, data } = error.response;
            if (status === 400 && data?.message) {
              setErrMsg(`Error : ${data.message}`);
            } else if (status === 413) {
              setErrMsg('File Size is too large');
            }
          } else {
            setErrMsg('Error occurred! Please Try Again');
          }
          setError(true);
        });
    }
  };

  return (
    <div style={{ marginRight: 32 }}>
      {isLoading && <Spinner />}

      {isError && <ErrorAlert message={errMsg} />}

      {isSuccess && <SuccessAlert message="Invoice Added Successfully !" />}

      <Form onSubmit={handleSubmit}>
        <section className="page-heading">Dashboard {'>'} Add Invoice</section>

        <div className="section-heading">Add Invoice</div>

        <section className="d-flex" style={{ gap: 20, flexWrap: 'wrap-reverse' }}>
          <div style={{ flexGrow: 3 }}>
            <FormCard>
              <div className="form-heading border-bottom-1">Ref No :-----</div>
              <InvoiceForm handleChange={setFormData} formData={formData} />
            </FormCard>
          </div>

          <div style={{ width: 300, flexGrow: 2 }}>
            <UploadDocCard isFile={true} onUpload={setFormData} id="invoiceLogo" />
          </div>
        </section>
      </Form>
    </div>
  );
}

export default AddInvoice;
