import React, { useEffect, useState } from 'react';
import Upload from '../../upload';
import styles from './style.module.scss';
import { CloseIcon, DocumentIcon, DownloadIcon } from '../../svg';
import { getFileDetails } from '../../../utils';

interface uploadType {
  isFile?: boolean;
  onUpload: Function;
  disabled?: boolean;
  fileData?: any;
  id: string;
}

function UploadDocCard({ isFile, onUpload, disabled, fileData, id }: uploadType) {
  //TODO: handleChange to be updated based on props. Below implementation if for testing purpose.
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (fileData) {
      setFile(fileData);
      setFileName(fileData.originalName);
    }
  }, [fileData]);

  const handleChange = (e: any) => {
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
      const file = URL.createObjectURL(e.target.files[0]);
      setFile(file);
      getFileDetails(e.target.files[0]).then((fileDetails) => onUpload({ file: fileDetails }));
      e.target.value = null;
    }
  };

  const handleRemove = (e: any) => {
    setFile('');
  };

  return (
    <div className={styles.card}>
      <div className="form-heading">Invoice Documents</div>
      {!disabled && (
        <div className={`${styles.uploadBlock} border-top-1`}>
          <div className={styles.uploadBlockInner}>
            <Upload id={id} type="doc" onChange={handleChange} disabled={disabled} />
          </div>
        </div>
      )}
      {!isFile && file !== '' && (
        <div className={`${styles.displayBlock} border-top-1`}>
          <div className={styles.displayBlockInner}>
            <div>
              <img src={file} alt="uploaded company logo" className={styles.image} />
            </div>
            <div className={styles.closeIcon}>
              <CloseIcon onClick={handleRemove} />
            </div>
          </div>
        </div>
      )}
      {isFile && file !== '' && (
        <div className={`${styles.fileDetails} border-top-1`}>
          <span>{fileName ? fileName.slice(-30) : ''}</span>
          <div className="d-flex align-items-center">
            {!disabled && <DocumentIcon width="32" height="36" />}
            <div className={styles.closeIcon}>
              {disabled ? (
                <a target="_blank" rel="noopener noreferrer" href={fileData.filePath} download>
                  <DocumentIcon width="32" height="32" />
                </a>
              ) : (
                <CloseIcon onClick={handleRemove} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
  export default UploadDocCard;
