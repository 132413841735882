import React from "react";

const ProgressBar = (props: { bgcolor: any; completed: any; }) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 25,
    backgroundColor: "#E8ECF3",
    // borderRadius: 50,
    margin: '25px 0px'
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    lineHeight: 1.7
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    // fontWeight: 'bold'
  }

  return (
    <div style={containerStyles}>
      <div style={{...fillerStyles, textAlign:'right'}}>
        <span style={{...labelStyles, fontWeight:'bold'}}>{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
