import React from 'react';
import styles from './index.module.scss';
import { DocumentIcon } from '../../svg';

const DocumentCard = (props: any) => {
  const { cStyle } = props.data;
  const { bankStatement, registrationCertificate, signedPlatformAgreement } = props.companyDocuments

  return (
    <div className={styles.card} style={cStyle}>
      <div className="form-heading">Company Documents</div>

      <div className="d-flex align-items-center justify-content-between border-top-1" style={{ padding: '20px 40px' }}>
        <span>Financial Books</span>
        <a target='_blank' rel="noopener noreferrer" href={bankStatement.filePath} download style={{ marginLeft: 20}}>
          <DocumentIcon width="32" height="36" />
        </a>
      </div>

      <div className="d-flex align-items-center justify-content-between border-top-1" style={{ padding: '20px 40px' }}>
        <span>Company Certificates</span>
        <a target='_blank' rel="noopener noreferrer" href={registrationCertificate.filePath} download style={{ marginLeft: 20}}>
          <DocumentIcon width="32" height="36" />
        </a>
      </div>

      <div className="d-flex align-items-center justify-content-between border-top-1" style={{ padding: '20px 40px' }}>
        <span>Letter of Introduction</span>
        <a target='_blank' rel="noopener noreferrer" href={signedPlatformAgreement.filePath} download style={{ marginLeft: 20}}>
          <DocumentIcon width="32" height="36" />
        </a>
      </div>
    </div>
  )
}

export default DocumentCard;
