import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UsersIcon } from '../../../components/svg';
import SearchFormInput from '../../../components/form/search';
import Form from '../../../components/form';
import UserList from './userList';
// import InvoiceResultList from './invoiceResultList';
import { getAllUsers } from '../../../api';
import Spinner from '../../../components/loader/Spinner';
import { pathProvider } from '../../../constants';
import { UserContext } from '../../../App';
import styles from './style.module.scss';

interface data {
  [key: string]: any;
}

const ViewAllUser = () => {
  const [data, setData] = useState<data>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  // const [searchData, setSearchdata] = useState([]);
  const userContext = useContext(UserContext);
  const nav = pathProvider(userContext.role);

  useEffect(() => {
    setLoading(true);
    getAllUsers()
      .then((res) => {
        setLoading(false);
        setData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <>
      {loading && <Spinner />}
      
      <section className="page-heading">Dashboard {'>'} Users</section>

      <section
        className={`d-flex justify-content-between align-items-center ${styles.searchSection}`}>
        <div className="flex-grow-1">
          <Form onSubmit={handleSearch}>
            <SearchFormInput
              className={styles.searchFormInput}
              placeholder="Search for user by Name, Id"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </Form>
        </div>
        <Link className="d-flex align-items-center text" to={nav.addUser}>
          <UsersIcon />
          <span>Add User</span>
        </Link>
      </section>

      {/* {searchData.length > 0 && (
        <InvoiceResultList title="Search Data" link="#" linkTitle="View all" data={searchData} />
      )} */}

      {<UserList title={`Users`} link={nav.users} data={data} />}
    </>
  );
};

export default ViewAllUser;
