import { clientAuthTokenKey, clientAuthRole, clientCurrency, clientName } from "./constants";

type role = 'client' | 'supplier' | 'treasury';

const getAuthToken = () => {
  return localStorage.getItem(clientAuthTokenKey);
};

const isUserAuthenticated = () => {
  
  if(getAuthToken()=="undefined" || getAuthToken()==null){
    console.log(getAuthToken()+ "false")
    return false
  }else{
    console.log(getAuthToken()+ "true")
    return true
  }

  //return getAuthToken() ? true : false;
};

const getClientRole:() => role = () => {
  const r = localStorage.getItem(clientAuthRole);
  const result: any = r || 'client';
  return result
}

const getClientCurrency = () => {
  const r = localStorage.getItem(clientCurrency);
  const result:any = r || ''
  return result
}

const getClientName = () => {
  const r = localStorage.getItem(clientName);
  const result:any = r || ''
  return result
}

const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};


function getFileExtension(fileName: string) {
  return fileName.substring(fileName.lastIndexOf('.')+1, fileName.length) || fileName;
} 

function getBase64(file:any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      if (e.target){
        const binaryData:any = e.target.result 
        const base64String = window.btoa(binaryData);
        resolve(base64String)
      }
    };
    reader.onerror = error => reject(error);
  });
}

async function getFileDetails(file: File) {
  const name = file.name
  const fileExtension = getFileExtension(file.name)
  const contentType = file.type
  const content = await getBase64(file)
  return {name, fileExtension, contentType, content}
}

function getCurrency(currency: String) {
  return currency == 'USD' ? '$' : '₦'
}



export { getAuthToken, isUserAuthenticated, clearStorage, getFileDetails, getClientRole, getClientCurrency, getCurrency, getClientName };
