import React, { createContext, useLayoutEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './routes/private';
import { paths, pathProvider } from './constants';
import Login from './views/login';
import Dashboard from './views/dashboard';
import ClientDashboard from './views/dashboard/client';
import SupplierDashboard from './views/dashboard/supplier';
import Suppliers from './views/suppliers';
import AddSupplier from './views/suppliers/addSupplier';
import ViewSupplier from './views/suppliers/viewSupplier';
import InvoiceSummary from './views/invoices/invoiceSummary';
import AddInvoice from './views/invoices/addInvoice';
import ViewInvoice from './views/invoices/viewInvoice';
import Users from './views/users';
import AddUser from './views/users/addUser';
import { getClientCurrency, getClientRole, getClientName } from './utils';
// import SupplierInvoiceSummary from './views/invoices/supplierInvoiceList';
import FinancierInvoiceSummary from './views/invoices/financierInvoices/invoiceSummary';
import ActivateSupplier from './views/suppliers/activation';
import ViewInvoiceSupplier from './views/invoices/viewInvoiceSupplier';
import ViewInvoiceFinancier from './views/invoices/viewInvoiceFinancier';
import CommingSoon from './views/commingSoon';
import ViewAllSupplier from './views/suppliers/viewAllsupplier';
import ViewAllUser from './views/users/viewAllUser';

export interface IUserContext {
  role: role;
  currency: string;
  name: string;
  setRole: (r: role) => void;
  setCurrency: (r: string) => void;
  setName: (r: string) => void;
}

export const UserContext = createContext<IUserContext>({
  role: null,
  currency: 'NGN',
  name: '',
  setRole: (r: role) => {},
  setCurrency: (r: string) => {},
  setName: (r: string) => {}
});

function App() {
  const [role, setRole] = useState<role>(null);
  const [currency, setCurrency] = useState('');
  const [name, setName] = useState('');
  const userInfo = {
    role,
    currency,
    name,
    setRole: (r: role) => {
      setRole(r);
    },
    setCurrency: (r: string) => {
      setCurrency(r);
    },
    setName: (r: string) => {
      setName(r);
    },
  };

  useLayoutEffect(() => {
    const clientRole = getClientRole();
    const clientCurrency = getClientCurrency();
    const clientName = getClientName();
    if (clientRole) {
      setRole(clientRole);
      setCurrency(clientCurrency);
      setName(clientName)
    }
  }, []);
  console.log('Role', role);
  const nav = pathProvider(role);
  return (
    <UserContext.Provider value={userInfo}>
      <Router>
        {nav && (
          <Switch>
            <Route exact path={['/', paths.login]}>
              <Login />
            </Route>
            <Route path={`${nav.activation}`}>
              <ActivateSupplier />
            </Route>
            {role === 'client' && (
              <PrivateRoute exact path={`${nav.dashboard}`}>
                <ClientDashboard />
              </PrivateRoute>
            )}
            {role === 'supplier' && (
              <PrivateRoute exact path={`${nav.dashboard}`}>
                <SupplierDashboard />
              </PrivateRoute>
            )}
            <PrivateRoute exact path={`${nav.dashboard}`}>
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute exact path={`${nav.suppliers}`}>
              <ViewAllSupplier />
            </PrivateRoute>
            <PrivateRoute path={`${nav.addSupplier}`}>
              <AddSupplier />
            </PrivateRoute>
            <PrivateRoute exact path={`${`${nav.suppliers}/:id`}`}>
              <ViewSupplier />
            </PrivateRoute>
            {(role === 'client' || role === 'treasury') && (
              <PrivateRoute exact path={`${nav.invoices}`}>
                <InvoiceSummary />
              </PrivateRoute>
            )}
            <PrivateRoute path={`${nav.addInvoice}`}>
              <AddInvoice />
            </PrivateRoute>
            {(role === 'client' || role === 'treasury') && (
              <PrivateRoute path={`${`${nav.invoices}/:id`}`}>
                <ViewInvoice />
              </PrivateRoute>
            )}
            {role === 'financier' && (
              <PrivateRoute path={`${`${nav.invoices}/:id`}`}>
                <ViewInvoiceFinancier />
              </PrivateRoute>
            )}
            {role === 'supplier' && (
              <PrivateRoute path={`${`${nav.invoices}/:id`}`}>
                <ViewInvoiceSupplier />
              </PrivateRoute>
            )}
            {/* {role === 'supplier' && (
              <PrivateRoute path={`${nav.invoices}`}>
                <SupplierInvoiceSummary />
              </PrivateRoute>
            )} */}
            {role === 'financier' && (
              <PrivateRoute path={`${nav.invoices}`}>
                <FinancierInvoiceSummary />
              </PrivateRoute>
            )}
            <PrivateRoute exact path={`${nav.users}`}>
              <ViewAllUser />
            </PrivateRoute>
            <PrivateRoute path={`${nav.addUser}`}>
              <AddUser />
            </PrivateRoute>
            <Route path="/">
              <CommingSoon />
            </Route>
          </Switch>
        )}
      </Router>
    </UserContext.Provider>
  );
}

export default App;
