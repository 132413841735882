import React from 'react';
import styles from './index.module.scss';
import placeHolderLogo from '../../../assets/img/person-default.png';
import { pathOr } from 'ramda';
import { Link } from 'react-router-dom';

interface IProfileCard {
  link?: string,
  className?: string,
  style?: {[key: string]: string | number},
  userDetails: {[key: string]: any}
}

const UserProfileCard = ({ userDetails, link, className = '', style }: IProfileCard) => {
  const { id, email, name, designation, profilePic } = userDetails;

  return (
    <div className={`${styles.card} ${className}`} style={style}>
      <div
        className={`d-flex justify-content-between align-items-center ${styles.cardHeader}`}>
        <p>{name}</p>
      </div>

      <div
        className={`d-flex align-items-center ${styles.cardProfile}`}>
        <div className="d-flex justify-content">
          <img
            className="flex-grow-1"
            style={{ width: '130px' }}
            src={pathOr(placeHolderLogo, ['filePath'], profilePic)}
            alt="user profile"
          />
        </div>
        <div style={{ paddingLeft: 12 }}>
          <p>{id}</p>
          <p
            style={{ wordBreak: 'break-all' }}
            >{email}</p>
          <p>{designation}</p>
        </div>
      </div>

      <div className={`d-flex justify-content-between align-items-center ${styles.cardFooter}`}>
        {link && (
          <Link className={`flex-grow-1 ${styles.supplierLink}`} to={link}>
            View user details
          </Link>
        )}
      </div>
    </div>
  );
};

export default UserProfileCard;
