import React, { useState, useEffect } from 'react';
import styles from './invoiceForm.module.scss';
import placeHolderLogo from '../../../assets/img/place-holder-image.png';
import moment from 'moment';
import { pathOr } from 'ramda';

function InvoiceForm({ formData }: any) {
  const { invoice, currency, supplierDetails } = formData;

  const [totalAmount, setTotalAmount] = useState('');

  useEffect(() => {
    const sum =
      (parseFloat(invoice?.value?.pretaxValue) || 0) + (parseFloat(invoice?.value?.taxValue) || 0);
    setTotalAmount(sum.toLocaleString());
  }, [invoice]);

  return (
    <div className={`${styles.FormSection}`}>
      <section className={styles.InvoiceInfo}>
        <div className="d-flex align-items-start justify-content-between">
          <div className="d-flex align-items-center">
            <label htmlFor="invoiceNo">Invoice No:</label>
            <input
              style={{ textAlign: 'left' }}
              className={styles.BottomInput}
              id="invoiceNo"
              name="invoiceNo"
              type="text"
              value={invoice?.invoiceNumber || ''}
              disabled
            />
          </div>

          <div>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ marginBottom: 20 }}>
              <label htmlFor="issueDate">Issue Date:</label>
              <input
                className={styles.BottomInput}
                id="issueDate"
                name="issueDate"
                value={invoice ? moment(invoice.issueDate).format('D MMM YYYY') : ''}
                type="text"
                data-type="date"
                autoComplete="off"
                disabled
              />
            </div>

            <div className="d-flex align-items-center justify-content-end">
              <label htmlFor="dueDate">Due Date:</label>
              <input
                className={styles.BottomInput}
                name="dueDate"
                id="dueDate"
                value={invoice ? moment(invoice.dueDate).format('D MMM YYYY') : ''}
                type="text"
                data-type="date"
                autoComplete="off"
                disabled
              />
            </div>
          </div>
        </div>
      </section>

      <section className={`d-flex justify-content-between ${styles.FormBase}`} style={{ gap: 20 }}>
        <div style={{ flexGrow: 3 }}>
          <div className="d-flex my-px-5">
            <label htmlFor="supplierRcNo">Supplier RC No:</label>
            <input
              id="supplierRcNo"
              value={supplierDetails?.registrationNumber || ''}
              type="text"
              disabled
            />
          </div>

          <div className="d-flex my-px-5">
            <label htmlFor="supplierId">Supplier Name:</label>
            <select
              id="supplierId"
              name="supplierId"
              disabled
              defaultValue={supplierDetails?.name || ''}>
              <option value={supplierDetails?.name || ''} defaultChecked>
                {supplierDetails?.name || ''}
              </option>
            </select>
          </div>

          <div className="d-flex my-px-5">
            <label htmlFor="supplierEmail">Supplier Email:</label>
            <input
              id="supplierEmail"
              value={supplierDetails?.contactDetails?.email || ''}
              type="email"
              disabled
            />
          </div>

          <div className="d-flex my-px-5">
            <label htmlFor="supplierAddress">Supplier Address:</label>
            <textarea
              id="supplierAddress"
              value={supplierDetails?.contactDetails?.billingAddress || ''}
              disabled
            />
          </div>
        </div>
        <div className={`d-flex align-items-center justify-content-center`} style={{ flexGrow: 2 }}>
          <img
            src={pathOr(placeHolderLogo, ['companyLogo', 'filePath'], supplierDetails)}
            width={180}
            alt="logo"
          />
        </div>
      </section>

      <section style={{ marginTop: 50 }}>
        <table className={styles.InvoiceTable}>
          <thead>
            <tr className="d-flex justify-content-between align-items-center border-bottom-1 py-px-5">
              <th>Description</th>
              <th className="d-flex justify-content-between align-items-center">
                <span>Amount&nbsp;In</span>
                <select
                  name="currency"
                  className={styles.CurrencyDropdown}
                  defaultValue={invoice?.value?.currency || currency}
                  disabled>
                  <option value="NGN">NGN</option>
                  <option value="USD">USD</option>
                </select>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              className={`d-flex justify-content-between align-items-center py-px-5 border-bottom-1`}>
              <td>Pre-Tax Value</td>
              <td>
                <input
                  className={styles.InputAmount}
                  type="number"
                  name="pretax"
                  step="0.01"
                  placeholder="Amount"
                  value={invoice?.value?.pretaxValue || 0}
                  min="0"
                  disabled
                />
              </td>
            </tr>

            <tr className={`d-flex justify-content-between align-items-center py-px-5`}>
              <td>Tax Value</td>
              <td>
                <input
                  className={styles.InputAmount}
                  type="number"
                  name="tax"
                  step="0.01"
                  placeholder="Amount"
                  value={invoice?.value?.taxValue || 0}
                  min="0"
                  disabled
                />
              </td>
            </tr>

            <tr
              className={`d-flex justify-content-between align-items-center py-px-15 ${styles.TotalSection}`}>
              <td style={{ fontWeight: 'bold', fontSize: 20 }}>Total Value</td>
              <td style={{ fontSize: '28px', fontWeight: 'bold' }}>
                {(invoice?.value?.currency || currency) === 'USD' ? '$' : '₦'} {totalAmount}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default InvoiceForm;
