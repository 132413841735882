import React from 'react';
import CardTag from '../cards/cardTag';
import moment from 'moment';
import styles from './index.module.scss';
import { ForwardIcon, MessageIcon } from '../../components/svg';
import { Link } from 'react-router-dom';
import { getCurrency } from '../../utils';
import { InvoiceData } from '../../interface';
interface InvoiceProps {
  link?: string;
  className?: string;
  style?: { [key: string]: string | number };
  invoiceData: InvoiceData;
}

enum cardStatusToTagText {
  New = 'New',
  OptedInForDiscounting = 'Pending Approval',
  ApprovedForDiscounting = 'Approved',
  InvoiceQuoted = 'Offered',
  InvoiceQuoteAccepted = 'Accepted',
  InvoiceDiscounted = 'Discounted',
  InvoicePaid = 'Processed',
  InvoiceOverDue = 'Overdue',
}

enum cardStatusToTagType {
  New = 'New',
  OptedInForDiscounting = 'Pending',
  ApprovedForDiscounting = 'Accepted',
  InvoiceQuoted = 'Accepted',
  InvoiceQuoteAccepted = 'Accepted',
  InvoiceDiscounted = 'Accepted',
  InvoicePaid = 'Accepted',
  InvoiceOverDue = 'Rejected',
}

const InvoiceCard = ({ invoiceData, link, className = '', style }: InvoiceProps) => {
  const {
    ref,
    status,
    title,
    description,
    invoiceValue,
    currency,
    invoiceNumber,
    issueDate,
    dueDate,
    messageCount,
  } = invoiceData;

  return (
    <div className={`${styles.card} ${className}`} style={style}>
      <div className={`d-flex justify-content-between align-items-center ${styles.cardHeader}`}>
        <p>
          Ref No: <strong>{ref}</strong>
        </p>
        <CardTag type={cardStatusToTagType[status]} text={cardStatusToTagText[status]} />
      </div>

      <div className={styles.cardProfile}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>

      <div className={styles.cardDetails}>
        <div className={`d-flex justify-content-between align-items-center`}>
          <div>
            <p>Invoice Value</p>
            <h2>{`${getCurrency(currency)} ${invoiceValue.toLocaleString()}`}</h2>
          </div>
          <div>
            <p style={{ textAlign: 'right' }}>Invoice No</p>
            <h2>{invoiceNumber}</h2>
          </div>
        </div>
        <hr />
        <div
          className={`d-flex justify-content-between align-items-center`}
          style={{ paddingTop: 10 }}>
          <div>
            <p>Issue Date:</p>
            <p>{moment(issueDate).format('ll')}</p>
          </div>
          <ForwardIcon />
          <div>
            <p>Due Date:</p>
            <p>{moment(dueDate).format('ll')}</p>
          </div>
        </div>
      </div>

      <div className={`d-flex justify-content-between align-items-center ${styles.cardFooter}`}>
        {link && (
          <Link className={`flex-grow-1 ${styles.invoiceLink}`} to={link}>
            View Invoice
          </Link>
        )}
        <div className={styles.message}>
          <MessageIcon />
          <p className={styles.messageBadge}>{messageCount}</p>
        </div>
      </div>
    </div>
  );
};

export default InvoiceCard;
