
export const pathProvider = (role: role) => {
  return {
    login: "/login",
    activation: '/supplier/activate',
    dashboard: `/${role}/dashboard`,
    invoices: `/${role}/invoices`,
    messages: `/${role}/messages`,
    partners: `/${role}/partners`,
    suppliers: `/${role}/suppliers`,
    users: `/${role}/users`,
    addUser:`/${role}/users/add-new`,
    help: `/${role}/help`,
    addSupplier: `/${role}/suppliers/add-new`,
    addInvoice: `/${role}/invoices/add-new`
  }
}

export const paths: { readonly [propName: string]: any } = {
  client :{
    dashboard: "/client/dashboard",
    invoices: "/client/invoices",
    messages: "/client/messages",
    partners: "/client/partners",
    suppliers: "/client/suppliers",
    users: "/client/users",
    addUser:'/client/users/add-new',
    help: "/client/help",
    addSupplier: "/client/suppliers/add-new",
    addInvoice: "/client/invoices/add-new"
    },
  supplier:{
    dashboard: "/supplier/dashboard",
    invoices: '/supplier/invoices'
  },
  treasury:{
    dashboard: "/treasury/dashboard",
  },
  login: "/login",
  forgotPassword: '/forgot-password',
};

export const clientAuthTokenKey = "infactClientAuthToken";
export const clientAuthRole = "infactClientAuthRole";
export const clientCurrency = "infactClientCurrency";
export const clientName = "infactUserName";
