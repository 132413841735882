import React, { useContext, useState, useEffect } from 'react';
import { pathProvider } from '../../../constants';
import { UserContext } from '../../../App';
import ModuleInfoCard from '../../../components/cards/moduleInfo';
import FinancingInfoCard from '../../../components/cards/financingInfo';
import FinancingPatternChart from '../../../components/cards/FinancingPatternChart';
import {
  getUserFinanceInfo,
  getUserFinancierCount,
  getUserFinanceChart,
  getClientInvoiceSummary,
  getAllSuppliers,
  getUserYearlyFinance
} from '../../../api';
import ProgressBar from '../../../components/progressBar';
import Spinner from '../../../components/loader/Spinner';
import moment from 'moment';
import { getCurrency } from '../../../utils';
import { sort } from 'ramda';

const sectionStyle = {
  backgroundColor: '#FFF',
  padding: 50,
  margin: '30px 0',
  borderRadius: 5,
};


interface MonthFinance {
  amount: number;
  month: string;
}
interface FinanceSuplierClearance {
  financeClearance: Array<MonthFinance>;
  supplierFinancing: Array<MonthFinance>;
}

function Dashboard() {
  const { role, currency } = useContext(UserContext);
  const nav = pathProvider(role);

  const [financing, setFinancing] = useState({finacingLimit: 1, totalSupplerFinacing: 0});
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [supplierCount, setSupplierCount] = useState(0);
  const [financierCount, setFinancierCount] = useState(0);

  const [yearlyFinance, setYearlyFinance] = useState<FinanceSuplierClearance>({
    financeClearance: [],
    supplierFinancing: [],
  });

  const [financierInfo, setFinancierInfo] = useState({
    approvedFinance: 0,
    pendingFinance: 0,
    declinedFinance: 0,
    approvedDetails: { disbursed: 0, notYetDisbursed: 0 },
    pendingDetails: { invoiceOptedIn: 0, invoiceOffered: 0 },
    declinedDetails: { acceptedQuotes: 0, declinedQuotes: 0 }
  });

  const setSortedYearlyFinance = ({ financeClearance, supplierFinancing }: FinanceSuplierClearance) => {
    const clearance = sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime(), financeClearance)
    const supplier = sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime(), supplierFinancing)
    setYearlyFinance({ financeClearance: clearance, supplierFinancing: supplier });
  };

  const [isLoading, setIsLoading] = useState(false);

  const modules = [
    {
      count: supplierCount,
      name: `Supplier${ supplierCount === 1 ? '' : 's'}`,
      link: nav.suppliers,
      linkText: 'Manage Suppliers'
    },
    {
      count: invoiceCount,
      name: `Invoice${ invoiceCount === 1 ? '' : 's'}`,
      link: nav.invoices,
      linkText: 'Manage Invoices'
    },
    // {
    //   count: financierCount,
    //   name: `Financier${ financierCount === 1 ? '' : 's'}`,
    //   link: nav.partners,
    //   linkText: 'Manage Financiers'
    // },
  ];

  const finances = [
    {
      head: {
        label: 'Payments due financier',
        value: financierInfo.approvedFinance,
      },
      left: {
        label: 'Disbursed',
        value: financierInfo.approvedDetails.disbursed,
      },
      right: {
        label: 'Not yet disbursed',
        value: financierInfo.approvedDetails.notYetDisbursed,
      },
      link: '#',
      currency,
    },
    {
      head: {
        label: 'Financier Offering',
        value: financierInfo.pendingFinance,
      },
      left: {
        label: 'Invoices opted in',
        value: financierInfo.pendingDetails.invoiceOptedIn,
      },
      right: {
        label: 'Invoices Offered',
        value: financierInfo.pendingDetails.invoiceOffered,
      },
      link: '#',
      currency,
    },
    {
      head: {
        label: 'Quote acceptance rate',
        value: financierInfo.declinedFinance,
      },
      left: {
        label: 'Accepted quotes',
        value: financierInfo.declinedDetails.acceptedQuotes,
      },
      right: {
        label: 'Declined quotes',
        value: financierInfo.declinedDetails.declinedQuotes,
      },
      link: '#',
      currency,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getUserFinanceChart(),
      getClientInvoiceSummary(),
      getAllSuppliers(),
      getUserFinancierCount(),
      getUserFinanceInfo(),
      getUserYearlyFinance()
    ])
    .then((res) => {
      const [finance, invoices, suppliers, financier, financeInfo, yearlyFinance] = res
      setFinancing(finance.data)
      setInvoiceCount(invoices.data.data.length)
      setSupplierCount(suppliers.data.length)
      setFinancierCount(financier.data)
      setFinancierInfo(financeInfo.data)
      setSortedYearlyFinance(yearlyFinance.data)
      setIsLoading(false)
    }).catch(err => {
      console.log(err);
      setIsLoading(false)
    });
  }, [])
  

  const financedProgress = (
    (financing.totalSupplerFinacing / financing.finacingLimit) *
    100
  ).toFixed(2);

  return (<>
    {isLoading && <Spinner />}
    {!isLoading && (
      <div style={{ paddingRight: 50 }}>
        <section className="section-heading d-flex justify-content-between">
          <p>Welcome back</p>
          <p style={{ fontSize: 20, fontWeight: 400 }}>{`As of ${moment().format('LL')}`}</p>
        </section>
        <section>
          <div className="d-flex" style={{ gap: 24 }}>
            {modules.map((moduleInfo) => (
              <div key={moduleInfo.name} style={{ width: '100%' }}>
                <ModuleInfoCard details={moduleInfo} />
              </div>
            ))}
          </div>
        </section>

        <section style={sectionStyle}>
          <div className="d-flex justify-content-between">
            <span>Total Supplier Financing</span>
            <span>Total invoice value</span>
          </div>

          <ProgressBar bgcolor={'#EA3323'} completed={financedProgress} />
          <div className="d-flex justify-content-between" style={{ fontWeight: 700, fontSize: 39 }}>
            <span>
              {getCurrency(currency)} {financing.totalSupplerFinacing.toLocaleString()}
            </span>
            <span>
              {getCurrency(currency)} {financing.finacingLimit.toLocaleString()}
            </span>
          </div>
        </section>

        <section>
          <div className="d-flex" style={{ gap: 24 }}>
            {finances.map((finance) => (
              <div key={finance.head.label} style={{ width: '100%' }}>
                <FinancingInfoCard details={finance} />
              </div>
            ))}
          </div>
        </section>
        { yearlyFinance.supplierFinancing.length &&
          <section style={sectionStyle}>
            <FinancingPatternChart
              firstData={yearlyFinance.supplierFinancing}
              secondData={yearlyFinance.financeClearance}
              firstLabel="Supplier financing"
              secondLabel="Finance clearance"
              title="12 Months Supplier Financing pattern"
            />
          </section>
        }
      </div>
    )}
    </>
  );
}

export default Dashboard;
