import React, {useEffect, useState} from "react";
import { CloseIcon, DocumentIcon } from "../../../components/svg";
import Upload from "../../../components/upload";
import styles from "./style.module.scss";
import {getFileDetails} from '../../../utils'

interface fileProps {
  id: string
  onUpload: Function
  value?: any
  disabled?: boolean,
}

const FileUploadBlock = ({id, value, disabled = false, onUpload}:fileProps) => {
  //TODO: handleChange to be updated based on props. Below implementation if for testing purpose.
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [isModified, setFileIsModified] = useState(false)
  const [downloadableFile, setDownloadableFile] = useState(false)
  useEffect( () => {
      if(value && value.originalName) {
        setFileName(`...${value.originalName.slice(-30)}`)
        setDownloadableFile(true)
        setFile(value)
      }
  }, [value])


  const handleChange = (e: any) => {
    let files = e.target.files;
    if (files[0]) {
      let name = `...${files[0].name.slice(-30)}`;
      const file = URL.createObjectURL(files[0]);
      //set file state variable
      setFileName(name)
      setFile(file);
      setDownloadableFile(false)
      getFileDetails(files[0]).then(
        (fileDetails) => onUpload(fileDetails)
      )
      // onUpload(files[0]);
    }
  };

  //TODO: handleRemove to be updated based on props. Below implementation if for testing purpose.
  const handleRemove = (e: any) => {
    //clear file state variable
    if(!disabled){
      setFile('')
      onUpload('')
    }
  };
  return (
    <div className={`d-flex ${styles.uploadBlock}`} style={{ width: '100%'}}>
      <div style={{ width: '50%' }}>
        <Upload
          id={id}
          type="doc"
          onChange={handleChange}
          disabled={disabled}
        />
      </div>

      {file !== '' && (
        <div className={`d-flex align-items-center justify-content-between ${styles.fileDetails}`} style={{ width: '50%' }}>
          <span>{fileName}</span>
          <div className="d-flex align-items-center">
            {downloadableFile ? <a href={value && value.filePath} download><DocumentIcon width="32" height="32"/></a>: <DocumentIcon width="32" height="36" />}
            <div className={styles.closeIcon}>
              <CloseIcon onClick={handleRemove} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FileUploadBlock;
