import React, { useEffect, useReducer, useState, useRef, useContext } from 'react';
import styles from './invoiceForm.module.scss';
import 'pikaday/css/pikaday.css';
import Pikaday from 'pikaday';
import placeHolderLogo from '../../../assets/img/place-holder-image.png';
import { getSupplier } from '../../../api';
import { pathOr } from 'ramda';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { pathProvider } from '../../../constants'
import { UserContext } from '../../../App';

const moneyRegex = /^(?:0|[1-9][0-9]*)(?:\.[0-9]{1,2})?$/;

const InvoiceForm = ({ handleChange, formData }: any) => {
  const { pretax, tax, currency, invoiceNo, supplierId } = formData;

  const [supplierData, setSupplierData] = useReducer((a: any, b: any) => [...a, ...b], [
    {
      id: 0,
      name: 'Select Supplier',
      registrationNumber: '',
      contactDetails: {
        email: '',
        telephone: '',
        billingAddress: '',
        state: '',
      },
    },
  ]);

  const [totalAmount, setTotalAmount] = useState('');
  
  const history = useHistory();
  const userContext = useContext(UserContext);
  const nav = pathProvider(userContext.role);

  useEffect(() => {
    getSupplier().then((res) => {
      setSupplierData(res.data);
    });
  }, []);

  const issueDateRef = useRef(document.createElement('input'));
  const dueDateRef = useRef(document.createElement('input'));

  useEffect(() => {
    const dates = [issueDateRef.current, dueDateRef.current];

    dates.forEach((element: HTMLInputElement) => {
      new Pikaday({
        field: element,
        format: 'D MMM YYYY',
        onSelect: (selectedDate) => {
          const newDate = moment(selectedDate).format('YYYY-MM-DD');
          const { name } = element;
          handleChange({ [name]: newDate });
        },
      });
    });
  }, [handleChange]);

  useEffect(() => {
    const sum = (parseFloat(pretax) || 0) + (parseFloat(tax) || 0);
    setTotalAmount(sum.toLocaleString());
  }, [pretax, tax]);

  const supplier = supplierData.filter(({ id }) => id === Number(supplierId));

  const supplierNameList = supplierData.map(({ id, name }) => (
    <option value={id} key={`${id}-supplierOption`}>
      {name}
    </option>
  ));

  const handleInput = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    handleChange({ [name]: value.trim() });
  };

  const handleMoneyInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const isEmpty = value === '';
    const isValidMoney = moneyRegex.test(value);

    if (isEmpty) {
      handleChange({ [name]: value });
    } else if (isValidMoney) {
      handleChange({ [name]: value });
    }
  };

  return (
    <div className={`${styles.FormSection}`}>
      <section className={styles.InvoiceInfo}>
        <div className="d-flex align-items-start justify-content-between">
          <div className="d-flex align-items-center">
            <label htmlFor="invoiceNo">Invoice No:</label>
            <input
              style={{ textAlign: 'left' }}
              className={styles.BottomInput}
              id="invoiceNo"
              name="invoiceNo"
              type="text"
              value={invoiceNo}
              onChange={handleInput}
              required
            />
          </div>

          <div>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ marginBottom: 20 }}>
              <label htmlFor="issueDate">Issue Date:</label>
              <input
                className={styles.BottomInput}
                id="issueDate"
                name="issueDate"
                ref={issueDateRef}
                type="text"
                data-type="date"
                autoComplete="off"
                required
              />
            </div>

            <div className="d-flex align-items-center justify-content-end">
              <label htmlFor="dueDate">Due Date:</label>
              <input
                className={styles.BottomInput}
                name="dueDate"
                id="dueDate"
                ref={dueDateRef}
                type="text"
                data-type="date"
                autoComplete="off"
                required
              />
            </div>
          </div>
        </div>
      </section>

      <section className={`d-flex justify-content-between ${styles.FormBase}`} style={{ gap: 20 }}>
        <div style={{ flexGrow: 3 }}>
          <div className="d-flex my-px-5">
            <label htmlFor="supplierId">Supplier Name:</label>
            <select id="supplierId" name="supplierId" onChange={handleInput}>
              {supplierNameList}
            </select>
          </div>

          <div className="d-flex my-px-5">
            <label htmlFor="supplierRcNo">Supplier RC No:</label>
            <input id="supplierRcNo" value={supplier[0]?.registrationNumber} type="text" disabled />
          </div>

          <div className="d-flex my-px-5">
            <label htmlFor="supplierEmail">Supplier Email:</label>
            <input
              id="supplierEmail"
              value={supplier[0]?.contactDetails.email}
              type="email"
              disabled
            />
          </div>

          <div className="d-flex my-px-5">
            <label htmlFor="supplierAddress">Supplier Address:</label>
            <textarea
              id="supplierAddress"
              value={supplier[0]?.contactDetails.billingAddress}
              disabled
            />
          </div>
        </div>
        <div className={`d-flex align-items-center justify-content-center`} style={{ flexGrow: 2 }}>
          <img
            src={pathOr(placeHolderLogo, [0, 'companyLogo', 'filePath'], supplier)}
            width={180}
            alt="logo"
          />
        </div>
      </section>

      <section style={{ marginTop: 50 }}>
        <table className={styles.InvoiceTable}>
          <thead>
            <tr className="d-flex justify-content-between align-items-center border-bottom-1 py-px-5">
              <th>Description</th>
              <th className="d-flex justify-content-between align-items-center">
                <span>Amount&nbsp;In</span>
                <select
                  name="currency"
                  className={styles.CurrencyDropdown}
                  defaultValue={currency}
                  onChange={handleInput}>
                  <option value="NGN">NGN</option>
                  <option value="USD">USD</option>
                </select>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              className={`d-flex justify-content-between align-items-center py-px-5 border-bottom-1`}>
              <td>Pre-Tax Value</td>
              <td>
                <input
                  className={styles.InputAmount}
                  type="number"
                  name="pretax"
                  step="0.01"
                  placeholder="Amount"
                  value={pretax}
                  min="0"
                  onChange={handleMoneyInput}
                />
              </td>
            </tr>

            <tr className={`d-flex justify-content-between align-items-center py-px-5`}>
              <td>Tax Value</td>
              <td>
                <input
                  className={styles.InputAmount}
                  type="number"
                  name="tax"
                  step="0.01"
                  placeholder="Amount"
                  value={tax}
                  min="0"
                  onChange={handleMoneyInput}
                />
              </td>
            </tr>
            <tr
              className={`d-flex justify-content-between align-items-center py-px-15 ${styles.TotalSection}`}>
              <td style={{ fontWeight: 'bold', fontSize: 20 }}>Total Value</td>
              <td style={{ fontSize: '28px', fontWeight: 'bold' }}>
                {currency === 'USD' ? '$' : '₦'} {totalAmount}
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <div className={`d-flex justify-content-between ${styles.ButtonSection}`}>
        <button type="submit" className={`${styles.btnRed}`}>
          Add Invoice
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            history.push(nav.invoices)
          }}
          className={`${styles.btnGrey}`}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default InvoiceForm;
