import React from "react";
import { Line } from 'react-chartjs-2';
import { map } from 'ramda'

interface MonthFinance {
  amount: number;
  month: string;
}

interface FinancingChartType {
  firstLabel: string,
  secondLabel: string,
  title: string,
  firstData: Array<MonthFinance>;
  secondData: Array<MonthFinance>;
}

function FinancingPatternChart({ firstData, secondData, title, firstLabel, secondLabel }: FinancingChartType) {
  const labels = map(({ month }) => month, firstData)
  const value1 = map(({ amount }) => amount, firstData)
  const value2 = map(({ amount }) => amount, secondData)
  let maxData = Math.max(...value1, ...value2);
  maxData = Math.ceil(maxData / 1000) * 1000;
  const stepSize = Math.trunc(maxData / 4)
  const  max = maxData - (maxData % 4) + stepSize

  const options = {
    stacked: false,
    interaction: {
      intersect: false,
    },
    radius: 7,
    scales: {
      y: {
        type: 'linear',
        display: true,
        min: 0,
        max,
        ticks: { stepSize }
      },
      y1: {
        type: 'linear',
        display: false,
        min: 0,
        max,
        ticks: { stepSize }
      },
    },
    plugins: {
      legend: {
        align: 'end',
        labels: {
          font: { size: 18 },
          color: '#000',
          boxWidth: 20,
        }
      },
    },
    aspectRatio: 3.1,
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: firstLabel,
        data: value1,
        borderColor: 'rgba(234, 51, 35, 0.02)',
        backgroundColor: 'rgba(234, 51, 35, 0.8)',
        yAxisID: 'y',
        fill: true,
        pointBackgroundColor: '#EA3323',
      },
      {
        label: secondLabel,
        data: value2,
        borderColor: 'rgba(4, 12, 71, 0.02)',
        backgroundColor: 'rgba(4, 12, 71, 0.49)',
        yAxisID: 'y1',
        fill: true,
        pointBackgroundColor: 'rgba(4, 12, 71, 0.76)',
      },
    ]
  };

  return (
    <div>
      <span style={{fontSize: 20, fontWeight: 400, padding: '0 15px'}}>{ title }</span>
      <Line data={chartData} type="line" options={options} />
    </div>
  );
}

export default FinancingPatternChart;
