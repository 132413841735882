import React, { useEffect, useReducer, useContext, useState } from 'react';
import FormCard from '../../../components/cards/form';
import UploadDocCard from '../../../components/cards/uploadDoc';
import InvoiceForm from './invoiceForm';
import Form from '../../../components/form';
import { useParams, useHistory } from 'react-router-dom';
import Card from '../../../components/cards/headingwithComponent';
import Switch from 'react-switch';
import { UserContext } from '../../../App';
import { getInvoiceDetail } from '../../../api';
import { pathProvider } from '../../../constants';
import ErrorAlert from '../../../components/error';
import SuccessAlert from '../../../components/success';
import Spinner from '../../../components/loader/Spinner';
import { approveDiscounting, markInvoiceProcessed } from '../../../api';

function ViewInvoice(props: any) {
  const { id }: any = useParams();
  const [switchValue, setSwitchValue] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const { role, currency } = useContext(UserContext);
  const [formData, setFormData] = useReducer((a: any, c: any) => ({ ...a, ...c }), { currency });
  const nav = pathProvider(role);
  const [bankRef, setBankRef] = useState('');

  useEffect(() => {
    setLoading(true);
    getInvoiceDetail(id)
      .then((res) => {
        setFormData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [id]);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const history = useHistory();

  useEffect(() => {
    let errorTime: NodeJS.Timeout, successTime: NodeJS.Timeout;
    if (isError) {
      errorTime = setTimeout(() => {
        setError(false);
      }, 1000);
    }
    if (isSuccess) {
      successTime = setTimeout(() => {
        setSuccess(false);
      }, 1000);
    }
    return () => {
      clearTimeout(errorTime);
      clearTimeout(successTime);
    };
  }, [isError, isSuccess]);

  const onApprovedDiscounting = () => {
    setLoading(true);
    approveDiscounting(id)
      .then(() => {
        setLoading(false);
        setSwitchValue(true);
        setSuccessMsg('Invoice approved');
        setSuccess(true);
        setTimeout(() => history.push(nav.invoices), 2500);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };

  const markInvoiceAsProcessed = () => {
    setLoading(true);

    const id = formData.invoice.id;
    markInvoiceProcessed(id)
      .then(() => {
        setLoading(false);
        setSuccessMsg('Invoice updated');
        setSuccess(true);
        setTimeout(() => history.push(nav.invoices), 2500);
      })
      .catch((error: any) => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <div style={{ marginRight: 32 }}>
      {isLoading && <Spinner />}

      {isError && <ErrorAlert />}

      {isSuccess && <SuccessAlert message={successMsg} />}

      <Form onSubmit={(e) => e.preventDefault()}>
        <section className="page-heading">Dashboard {'>'} View Invoice</section>

        <div className="section-heading">View Invoice</div>
        <section className="d-flex" style={{ gap: 20, flexWrap: 'wrap-reverse' }}>
          <div style={{ flexGrow: 3 }}>
            <FormCard>
              <div
                className="d-flex align-items-center justify-content-between border-bottom-1"
                style={{ paddingRight: 30 }}>
                <div className="form-heading">Ref No: {formData.invoice?.id}</div>
                {formData.invoice?.status === 'OptedInForDiscounting' && role === 'treasury' && (
                  <Switch
                    uncheckedIcon={<p>Approve discounting request</p>}
                    checkedIcon={<p>Approve discounting request</p>}
                    width={270}
                    onChange={onApprovedDiscounting}
                    checked={switchValue}
                  />
                )}
              </div>
              <InvoiceForm formData={formData} />
            </FormCard>
          </div>
          <div style={{ width: 300, flexGrow: 2 }}>
            <UploadDocCard
              isFile={true}
              onUpload={setFormData}
              disabled={true}
              fileData={formData.invoice?.attachment || {}}
              id="supplierLogo"
            />
            {role === 'supplier' && (
                <Card data={{ title: 'Discount - 4 days remaining', cStyle: { marginTop: 20 } }}>
                  <button
                    onClick={() => {
                      alert('opted for discount');
                    }}
                    style={{
                      padding: '15px 80px',
                      backgroundColor: '#EA3323',
                      color: 'white',
                      margin: '20px 30px',
                      borderRadius: 5,
                      outline: 'none',
                      border: 'none',
                    }}>
                    Accept
                  </button>
                </Card>
              )}

            {formData &&
                formData.invoice &&
                (formData.invoice.status === 'InvoiceDiscounted' ||
                  formData.invoice.status === 'InvoiceOverDue') && (
                  <Card data={{ title: 'Confirm transaction details', cStyle: { marginTop: 20 } }}>
                    <div style={{ padding: '10px 20px' }}>
                      <div className={`form-group`}>
                        <label className="form-label" htmlFor="bankRef">
                          Enter the bank reference number (optional)
                        </label>
                        <input
                          style={{ borderColor: 'black' }}
                          onChange={(e) => setBankRef(e.target.value)}
                          type="text"
                          id="bankRef"
                          value={bankRef || ''}
                        />
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingTop: 20,
                        }}>
                        <button
                          onClick={() => markInvoiceAsProcessed()}
                          style={{
                            padding: '10px 25px',
                            backgroundColor: '#EA3323',
                            color: 'white',
                            borderRadius: 5,
                            outline: 'none',
                            border: 'none',
                          }}>
                          Confirm Payment
                        </button>
                      </div>
                    </div>
                  </Card>
                )}
          </div>
        </section>
      </Form>
    </div>
  );
}

export default ViewInvoice;
