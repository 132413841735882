import axios from "axios";
import { getAuthToken, clearStorage } from "./utils";
import { logOut } from "./api";
import { useHistory } from "react-router-dom";
import { paths } from "./constants";

const baseURL = process.env.REACT_APP_SERVER_BASE_URL || "/api/v1"

//const history = useHistory();
const signOut = () => {
  logOut().then( () => {
    clearStorage();
    //history.push(paths.login);
    window.location.href = '/'
  }).catch(() => {
    clearStorage();
    window.location.href = '/'

    //history.push(paths.login);
  })
};

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": '*'
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    const authToken = getAuthToken();
    console.log("authToken  ", authToken)
    if (authToken) {
      config.headers["Authorization"] = authToken;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("response", error)
    if(error.response && error.response.status === 401){
      clearStorage();
      signOut()
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
