import React, {useReducer, useState, useEffect, useContext} from "react";
import FormCard from "../../../components/cards/form";
import { useHistory } from "react-router-dom";
import UploadLogoCard from "../../../components/cards/uploadLogo";
import SupplierForm from "./SupplierForm";
import Form from '../../../components/form';
import Spinner from "../../../components/loader/Spinner";
import {addSupplier} from '../../../api'
import ErrorAlert from "../../../components/error";
import SuccessAlert from "../../../components/success";
import {UserContext} from '../../../App';
import { pathProvider } from '../../../constants'

function AddSupplier() {
  const [formData, setFormData] = useReducer((a:any, c:any)=> ({...a, ...c}), {});
  const [isLoading, setLaoding] = useState(false);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const history = useHistory();
  const userContext = useContext(UserContext);
  const nav = pathProvider(userContext.role);

  useEffect(() => {
    let errorTime: NodeJS.Timeout, successTime: NodeJS.Timeout;
    if(isError){
      errorTime = setTimeout(()=> {setError(false)}, 2000);
    }
    if(isSuccess){
      successTime = setTimeout(()=> {setSuccess(false)}, 1000);
    }
    return () => {
      clearTimeout(errorTime);
      clearTimeout(successTime);
    }
  }, [isError, isSuccess])


  const handleSubmit = (e:any) => {
    e.preventDefault();
    setLaoding(true);
    // setTimeout(()=> setLaoding(false), 2000)
    const form = {
      "name": formData.name,
      "registrationNumber": formData.rcNumber,
      "contactDetails": {
        "email": formData.email,
        "telephone": formData.contact,
        "billingAddress": formData.billingAddress,
        "state": formData.state
      },
      "bankDetails": formData.bankName && {
        "bankName": formData.bankName,
        "accountNumber": formData.acNumber,
        "accountName": formData.acName
      },
      "officialRepresentative": {
        "name": formData.representativeName,
        "designation": formData.designation,
        "emailAddress": formData.representativeEmail,
        "phoneNumber": formData.representativeTel
      },
      "companyLogo": formData.file,
      "registrationCertificate": formData.supplierCompanyCertificate,
      "bankStatement": formData.supplierCompanyStamp,
      "signedPlatformAgreement":formData.agreement
    }

    console.log(form)

    if(isValidateData(formData)){
      // console.log(formData)
     

      addSupplier(form).then( () => {
        setLaoding(false);
        setSuccess(true);
        setTimeout(()=> history.push(nav.suppliers), 2000)
      })
      .catch((error: any) => {
        setLaoding(false);
        setError(true);
        console.log(error)
      });
    } else {
      setLaoding(false);
      setError(true);
    }
    
  }

  const handleChange = (e: any): any => {
    let data:any = {...formData};
    data[e.target.id]= e.target.value.trim();
    console.log("formDataChange", data)
    setFormData(data);
  }

  const isValidateData= (obj: any):boolean => {
    for(let k in obj){
      if(typeof(obj[k]) === "string" && obj[k].trim().length < 1){
        return false;
      }
    }
    return true
  };

  return (
    <div style={{ marginRight: 32 }}>
      {isLoading && <Spinner />}

      {isError && <ErrorAlert />}

      {isSuccess && <SuccessAlert message="Supplier added Successfully!"/>}

      <Form onSubmit={handleSubmit}>
        <section className="page-heading">Dashboard {'>'} Suppliers {'>'} Add Supplier</section>

        <div className="section-heading">Add Supplier</div>

        <section className="d-flex" style={{ gap: 30, flexWrap: 'wrap-reverse' }}>
          <div style={{ flexGrow: 3, width: 500 }}>
            <FormCard>
              <div
                className="d-flex align-items-center justify-content-between border-bottom-1"
                style={{ paddingRight: 30 }}
              >
                <div className="form-heading">Supplier</div>
              </div>
              <div className="col-12">
                <SupplierForm handleChange={handleChange} formData={formData} setFile={setFormData}/>
              </div>
            </FormCard>
          </div>
          <div style={{ width: 300, flexGrow: 1}}>
            <UploadLogoCard
              onUpload={setFormData}
            />
          </div>
        </section>
      </Form>
    </div>
  );
}

export default AddSupplier;
