import React from 'react';
import { SearchIcon } from '../../svg';
import styles from './style.module.scss';

interface ISearchFormInputProp {
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  value: string;
  placeholder: string;
  className?: string;
  style?: Object;
}

function SearchFormInput({ onChange, placeholder, style, value, className = '' }: ISearchFormInputProp) {
  return (
    <div
      className={`d-flex justify-content-between align-items-center ${styles.searchForm} ${className}`}
      style={style}
    >
      <input
        className={styles.searchInput}
        type="search"
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={value}
      />
      <button
        className="d-flex align-items-center"
        type="submit"
      >
        <SearchIcon />
      </button>
    </div>
  )
}

export default SearchFormInput;
