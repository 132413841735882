import React from 'react';
import styles from './index.module.scss';

const Card = (props: any) => {
  const { title, cStyle } = props.data;
  return (
    <div className={styles.card} style={cStyle}>
      <div className="border-bottom-1">
        <div className="form-heading">{title}</div>
      </div>
      {props.children}
    </div>
  );
};

export default Card;
