import React, {useContext} from "react";
import { NavLink } from "react-router-dom";
import styles from "./style.module.scss";
import { pathProvider } from "../../constants";
import { UserContext } from '../../App';
import {
  DashboardIcon,
  InvoiceIcon,
  MessageIcon,
  DollarIcon,
  TruckIcon,
  UsersIcon,
  HelpIcon,
} from "../svg";



function Sidebar() {
  const userContext = useContext(UserContext);
  const nav = pathProvider(userContext.role);
  const supplierSidebar = [
    { name: "Dashboard", link: nav.dashboard, icon: <DashboardIcon /> },
    // { name: "Invoices", link: nav.invoices, icon: <InvoiceIcon /> },
    // { name: "Help", link: nav.help, icon: <HelpIcon /> },
  ];
  const clientSidebar = [
    { name: "Dashboard", link: nav.dashboard, icon: <DashboardIcon /> },
    { name: "Invoices", link: nav.invoices, icon: <InvoiceIcon /> },
    { name: "Suppliers", link: nav.suppliers, icon: <TruckIcon /> },
    { name: "Users", link: nav.users, icon: <UsersIcon /> },
    // { name: "Help", link: nav.help, icon: <HelpIcon /> },
  ];
  const financierSideBar = [
    { name: "Invoices", link: nav.invoices, icon: <InvoiceIcon /> },
  ];
  // can be changed when treasury will be implemented
  let sideBarList = [];
  if(userContext.role === 'supplier'){
    sideBarList = supplierSidebar
  } else if(userContext.role === 'client'){
    sideBarList = clientSidebar
  } else {
    sideBarList = financierSideBar
  }

  return (
    <aside className={styles.sidebar}>
      {nav && sideBarList.map((item) => (
        <NavLink
          key={item.name}
          to={item.link}
          className={styles.navLink}
          activeClassName={styles.selected}
        >
          <div>{item.icon}</div>
          <div className={styles.linkText}>{item.name}</div>
        </NavLink>
      ))}
    </aside>
  );
}

export default Sidebar;
