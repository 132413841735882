import React from 'react'
import styles from "./error.module.scss"
import {ErrorIcon} from '../svg';

interface ErrorPropsType {
    message?: string,
}
const ErrorAlert = ({message}: ErrorPropsType) => (
    <div className={styles.container}>
        <div className={styles.contentBox}>
        <span>
            <ErrorIcon width="25px" height="25px"/>
            <p> {message || 'Error occurred ! Please try again'}</p>
        </span>
        </div>
    </div>
);

export default ErrorAlert;