import React, {useReducer, useState, useEffect, useContext} from "react";
import FormCard from "../../../components/cards/form";
import { useHistory } from "react-router-dom";
import UploadLogoCard from "../../../components/cards/uploadLogo";
import UserForm from "./UserForm";
import Form from '../../../components/form';
import Spinner from "../../../components/loader/Spinner";
import { addUser } from '../../../api'
import ErrorAlert from "../../../components/error";
import SuccessAlert from "../../../components/success";
import {UserContext} from '../../../App';
import { pathProvider } from '../../../constants'

function AddUser() {
  const [formData, setFormData] = useReducer((a:any, c:any)=> ({...a, ...c}), {});
  const [isLoading, setLaoding] = useState(false);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const history = useHistory();
  const userContext = useContext(UserContext);
  const nav = pathProvider(userContext.role);
  useEffect(() => {
    let errorTime: NodeJS.Timeout, successTime: NodeJS.Timeout;
    if(isError){
      errorTime = setTimeout(()=> {setError(false)}, 2000);
    }
    if(isSuccess){
      successTime = setTimeout(()=> {setSuccess(false)}, 1000);
    }
    return () => {
      clearTimeout(errorTime);
      clearTimeout(successTime);
    }
  }, [isError, isSuccess])


  const handleSubmit = (e:any) => {
    e.preventDefault();
    setLaoding(true);
    // setTimeout(()=> setLaoding(false), 2000)
    if(isValidateData(formData)){
      // console.log(formData)
      const form = {
        "email": formData.email,
        "name": formData.name,
        "designation": formData.designation,
        "phoneNumber": formData.contact,
        "role": "treasury"
      }
    

      addUser(form).then( () => {
        setLaoding(false);
        setSuccess(true);
        setTimeout(()=> history.push(nav.users), 2000)
      })
      .catch((error: any) => {
        setLaoding(false);
        setError(true);
        console.log(error)
      });
    } else {
      setLaoding(false);
      setError(true);
    }
    
  }

  const handleChange = (e: any): any => {
    let data:any = {...formData};
    data[e.target.id]= e.target.value.trim();
    console.log("formDataChange", data)
    setFormData(data);
  }

  const isValidateData= (obj: any):boolean => {
    for(let k in obj){
      if(typeof(obj[k]) === "string" && obj[k].trim().length < 1){
        return false;
      }
    }
    return true
  };

  return (
    <Form onSubmit={handleSubmit}>
      {isLoading && <Spinner />}
      {isError && <ErrorAlert />}
      {isSuccess && <SuccessAlert message="User Added Successfully !"/>}
      <section style={{ padding: "20px" }}>
        Users {">"} Add User
      </section>
      <section>
        <div className="section-heading">Add User</div>
        <div className="row">
          <div className="col-xl-8 order-2 order-xl-1">
            <FormCard>
            <div className="form-heading">User</div>
            <hr  className="horizontal-line" />
              <UserForm handleChange={handleChange} formData={formData} setFile={setFormData}/>
            </FormCard>
          </div>
          {/* <div className="col-xl-4 order-1 order-xl-2">
            <UploadLogoCard onUpload={setFormData}/>
          </div> */}
        </div>
      </section>
    </Form>
  );
}

export default AddUser;
