import React, { useState, useEffect, useContext } from 'react';
import InvoiceList from './invoiceList';
import { getFinancierInvoices, financierInvoiceSearch } from '../../../../api';
import Spinner from '../../../../components/loader/Spinner';
import { filter, includes, propSatisfies, __ } from 'ramda';
import { pathProvider } from '../../../../constants';
import { UserContext } from '../../../../App';
import { InvoiceData } from '../interface';
import { useLocation } from "react-router-dom";

const newInvoicesStatus = ['ApprovedForDiscounting'];
const offeredStatus = ['InvoiceQuoted', 'InvoiceQuoteAccepted'];
const discountedStatus = ['InvoiceDiscounted'];
const paidStatus = ['InvoicePaid'];
const overDueStatus = ['InvoiceOverDue'];

const FinancierInvoiceSummary = () => {
  const { role } = useContext(UserContext);
  const nav = pathProvider(role);

  const [data, setData] = useState<Array<InvoiceData>>([]);
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const location = useLocation();

  const newInvoices = filter<InvoiceData>(propSatisfies(includes(__, newInvoicesStatus), 'status'), data);
  const offeredInvoices = filter<InvoiceData>(propSatisfies(includes(__, offeredStatus), 'status'), data);
  const discountedInvoices = filter<InvoiceData>(propSatisfies(includes(__, discountedStatus), 'status'), data);
  const processedInvoices = filter<InvoiceData>(propSatisfies(includes(__, paidStatus), 'status'), data)
  const overDueInvoices = filter<InvoiceData>(propSatisfies(includes(__, overDueStatus), 'status'), data)


  const fetchInvoiceData = (query: any): Promise<any> => {
    return query ? financierInvoiceSearch(query) : getFinancierInvoices();
  };

  const getInvoiceData = (query: any) => {
    setLoading(true);
    fetchInvoiceData(query)
    .then(res => {
      setData(res.data?.data || res.data);
      setLoading(false);
      setIsSearch(!!query)
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search).get('invoice');
    getInvoiceData(query);
  }, [location]);

  return (
    <>
      {loading && <Spinner />}

      <section className="page-heading">Dashboard {'>'} Invoices</section>

      {
        !loading && (
          <section>
            {isSearch ? (
              <InvoiceList group="Search results" link={nav.invoices} data={data} />
            ) : (
              <>
                <InvoiceList group="Available" link={nav.invoices} data={newInvoices} />
                <InvoiceList group="Offered" link={nav.invoices} data={offeredInvoices} />
                <InvoiceList group="Discounted" link={nav.invoices} data={discountedInvoices} />
                <InvoiceList group="Processed" link={nav.invoices} data={processedInvoices} />
                <InvoiceList group="Overdue" link={nav.invoices} data={overDueInvoices} />
              </>
            )}
          </section>
        )
      }
    </>
  );
};

export default FinancierInvoiceSummary;
