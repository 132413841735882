import React, { FormEvent, ReactNode } from "react";

interface FormType {
  onSubmit(e: FormEvent<HTMLFormElement>): void;
  children: ReactNode;
  autoComplete?: "on" | "off";
  name?: string;
  noValidate?: boolean;
}

function Form({ onSubmit, children, ...rest }: FormType) {
  return (
    <form onSubmit={onSubmit} {...rest}>
      {children}
    </form>
  );
}

export default Form;
