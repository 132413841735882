import React, { useState } from 'react';
import styles from './style.module.scss';
import InvoiceCard from './invoiceCard';
import { map, pathOr, length, sum } from 'ramda';
import { getCurrency } from '../../../../utils';
import { InvoiceData } from '../interface';

interface InvoiceListProps {
  group: string;
  data: Array<InvoiceData>;
  link: string;
}

function getTotalAmount(invoices: any) {
  if (length(invoices) === 0) {
    return '0';
  } else {
    const currency = invoices[0].currency;
    const totalValue = sum(map<number, number>(pathOr(0, ['invoiceValue']), invoices));

    return `${getCurrency(currency)} ${totalValue.toLocaleString()}`;
  }
}

const InvoiceList = ({ group, data, link }: InvoiceListProps) => {

  const [isShowingAll, setIsShowingAll] = useState(false);

  const toggleIsShowingAll = () => {
    setIsShowingAll((previousValue) => !previousValue);
  };

  const buttonText = isShowingAll ? 'View less' : 'View all';

  const isNotEmpty = data.length > 0;

  const ListInvoice = data.map((el) => {
    return (
      <InvoiceCard
        className={!isShowingAll ? styles.card : ''}
        key={`${group}-inCard-${el.ref}`}
        link={`${link}/${el.ref}`}
        invoiceData={el}
      />
    );
  });

  return (
    <>
      <section
        className="section-heading d-flex justify-content-between align-items-center"
        style={{ marginRight: 62 }}>
        <p className={styles.invoiceTitle}>
          {group} - {getTotalAmount(data)}
        </p>
        {isNotEmpty && (
          <button onClick={toggleIsShowingAll} className={styles.btnStyle}>
            {buttonText}
          </button>
        )}
      </section>

      <div className={styles.cardContainerLayout}>
        <div className={isShowingAll ? styles.cardContainerAll : styles.cardContainer}>
          {ListInvoice}
        </div>
      </div>
    </>
  );
};

export default InvoiceList;
