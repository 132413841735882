import React from "react";
import { UploadIcon } from "../svg";
import styles from "./style.module.scss";

const variant = {
  img: {
    text:
      "Select the image you wish to upload. Please ensure it is in JPG or PNG format",
    accept: ".png,.jpeg",
  },
  doc: {
    text:
      "Select the document you wish to upload. Please ensure it is in PDF, JPG or PNG format",
    accept: ".png,.jpeg,.pdf",
  },
};

interface UploadType {
  id: string;
  type: "img" | "doc";
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  disabled?: boolean
}

function Upload({ id, type, onChange, disabled }: UploadType) {
  return (
    <label className={styles.uploadLabel} htmlFor={id}>
      <input
        type="file"
        accept={variant[type].accept}
        className={styles.fileInput}
        data-testid={id}
        id={id}
        onChange={onChange}
        disabled={disabled}
      />
      <div>
        <UploadIcon />
      </div>
      <div className={styles.text}>{variant[type].text}</div>
    </label>
  );
}

export default Upload;
