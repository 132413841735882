import React, { useEffect, useReducer, useContext,useState } from "react";
import FormCard from "../../../components/cards/form";
import UploadDocCard from "../../../components/cards/uploadDoc";
import InvoiceForm from "./invoiceForm";
import Form from '../../../components/form';
import {useLocation, useParams, useHistory} from 'react-router-dom';
import Card from "../../../components/cards/headingwithComponent";
import Spinner from '../../../components/loader/Spinner';
import Switch from "react-switch";
import { UserContext } from "../../../App";
import ProfileCard from '../../../components/cards/profilecard';
import DocumentCard from '../../../components/cards/documentCard';
import {pathOr, filter, includes} from 'ramda'
import styles from './viewInvoice.module.scss';
import InvoiceList from "../invoiceSummary/invoiceList";
import QuoteList from "./quoteList";
import {getInvoiceDetail, optInForDiscounting, getInvoiceQuotes, acceptInvoiceQuote, rejectInvoiceQuote} from '../../../api';
import { pathProvider } from '../../../constants'
import ErrorAlert from "../../../components/error";
import SuccessAlert from "../../../components/success";


function ViewInvoiceSupplier(props:any) {
  const { id }:any = useParams();
  const [formData, setFormData] = useReducer((a:any, c:any)=> ({...a, ...c}), {currency:'NGN'});
  const [switchValue, setSwitchValue] = useState(false);
  const [invoiceQuotes, setInvoiceQuotes] = useState([])

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("Invoice Added Successfully !")
  const history = useHistory();

  console.log("formdata", formData);

  const fetchInvoiceQuotes  = () => {
    getInvoiceQuotes(id).then( (res) => {
       console.log("Invoice Quotes", res.data)
       setInvoiceQuotes(res.data)
    }).catch(err=> console.log("err", err))
  }

  const onInvoiceDetailsFetched = (invoiceDetails:any) => {
    setFormData(invoiceDetails)
    if(invoiceDetails.invoice.status != 'New' && invoiceDetails.invoice.status != 'OptedInForDiscounting') {
      fetchInvoiceQuotes()
    }
  }

  useEffect(()=> {
    getInvoiceDetail(id).then(res=>{ console.log("Invoicedata", res.data); onInvoiceDetailsFetched(res.data)}).catch(err=> console.log("err", err));
  }, [id]);

  const userContext = useContext(UserContext);
  const nav = pathProvider(userContext.role);

  useEffect(() => {
    let errorTime: NodeJS.Timeout, successTime: NodeJS.Timeout;
    if(isError){
      errorTime = setTimeout(()=> {setError(false)}, 1000);
    }
    if(isSuccess){
      successTime = setTimeout(()=> {setSuccess(false)}, 1000);
    }
    return () => {
      clearTimeout(errorTime);
      clearTimeout(successTime);
    }
  }, [isError, isSuccess])
  const [companyDetails, setCompanyDetails] = useState({})

  const companyProfile = {
    name : pathOr('',['companyDetails', 'name'], companyDetails),
    registrationNumber: pathOr('',['companyDetails', 'registrationNumber'], companyDetails),
    contactEmail: pathOr('',['companyDetails', 'contactDetails', 'email'], companyDetails),
    address: pathOr('',['companyDetails', 'contactDetails', 'billingAddress'], companyDetails),
    financeDetails : {
        financedValue: pathOr(0,['supplierFinancedSummary', 'discountedValue'], companyDetails), 
        currency: pathOr('NGN',['companyDetails', 'defaultCurrency'], companyDetails)
    }, 
    companyLogo: pathOr({},['companyDetails', 'companyLogo'], companyDetails)
}

const companyDocuments = {
    bankStatement: pathOr('',['companyDetails', 'bankStatement'], companyDetails),
    registrationCertificate: pathOr('',['companyDetails', 'registrationCertificate'], companyDetails),
    signedPlatformAgreement: pathOr('',['companyDetails', 'signedPlatformAgreement'], companyDetails),
}


  const acceptForDiscounting = () => {
    setLoading(true)
    optInForDiscounting(id).then(() => {
      setLoading(false);
      setSuccessMessage("Opted in successfully !")
      setSuccess(true);
      setTimeout(()=>history.push(nav.invoices),2500)
    }).catch( (error) => {
      setLoading(false);
      setError(true);
      console.log(error)
    })
  }

  const onQuoteAcceptanceOrRejection = (quoteId: Number, isAccepted: boolean) => {
    setLoading(true) 

    if(isAccepted) {
        acceptInvoiceQuote(quoteId).then(() => {
          setLoading(false);
          setSuccessMessage("Invoice quote accepted !")
          setSuccess(true);
          setTimeout(()=>history.push(nav.invoices),2500)
        }).catch( (error) => {
          setLoading(false);
          setError(true);
          console.log(error)
        })
        
    }else{
         rejectInvoiceQuote(quoteId).then(() => {
          setLoading(false);
          setSuccessMessage("Invoice quote declined!")
          setSuccess(true);
          setTimeout(()=>history.push(nav.invoices),2500)
        }).catch( (error) => {
          setLoading(false);
          setError(true);
          console.log(error)
        })
    }
  }


  return (
    <div style={{ marginRight: 32 }}>
      {isLoading && <Spinner />}

      {isError && <ErrorAlert />}

      {isSuccess && <SuccessAlert message={successMessage}/>}

      <section className="page-heading">
        Dashboard {'>'} Invoices {'>'} Ref No: {formData.invoice?.id}
      </section>

      <section className="d-flex" style={{ gap: 20, flexWrap: 'wrap-reverse' }}>
        <div style={{ flexGrow: 2, width: 600 }}>
          <FormCard>
            <div className="form-heading border-bottom-1">Ref No: <span style={{ fontWeight: 'bold' }}>{formData.invoice?.id}</span></div>
            <InvoiceForm formData={formData} />
          </FormCard>
        </div>

        <div style={{ width: 300, flexGrow: 1 }}>
          <UploadDocCard
            isFile={true}
            onUpload={setFormData}
            disabled={true}
            fileData={formData.invoice?.attachment || {}}
            id="supplierLogo"
          />
          {/* <DocumentCard companyDocuments= {companyDocuments} data={{cStyle:{width:'100%',marginTop:"10%"}}} /> */}
          {formData.invoice?.status === 'New' && (
            <Card data={{title:`Discount - ${ formData.config.daysRemainingForOptIn}  days remaining`, cStyle:{marginTop:20}}}>
              <button
                className={`${styles.btnAcpt}`}
                onClick={()=> {acceptForDiscounting()}}
              >
                Opt In
              </button>
            </Card>
          )}
        </div>
      </section>

      { formData.invoice && formData.invoice.status !== 'New' && formData.invoice.status !== 'OptedInForDiscounting' &&
        <section>
          <div className="section-heading" style={{marginTop:20}}>{invoiceQuotes.length} Financiers Quotes shared</div>
          <QuoteList data={invoiceQuotes} invoiceDueDate={formData.invoice.dueDate} onQuoteAcceptanceOrRejection={onQuoteAcceptanceOrRejection}/>
        </section>
      }
    </div>
  );
}

export default ViewInvoiceSupplier;
