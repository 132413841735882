import React from 'react';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { getCurrency } from '../../../utils';

interface FinancingInfoCardType {
  details: {
    link: string;
    currency: string;
    head: {
      label: string;
      value: number;
    };
    left: {
      label: string;
      value: number;
    };
    right: {
      label: string;
      value: number;
    };
  };
}

const options = {
  responsive: true,
  rotation: 180,
  layout: {
    padding: {
      top: 10,
      bottom: 20,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  aspectRatio: 1.7,
  cutout: '55%',
};

function FinancingInfoCard({
  details: { head, left, right, link, currency },
}: FinancingInfoCardType) {
  const data = {
    labels: [left.label, right.label],
    datasets: [
      {
        data: [left.value, right.value],
        backgroundColor: ['#EA3323', 'rgba(4, 12, 71, 0.49)'],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div className={styles.card}>
      <div className={styles.infoBlock}>
        <div className={styles.head}>
          <h3>{head.label}</h3>
          <p>
            {getCurrency(currency)} {head.value.toLocaleString()}
          </p>
        </div>

        <Doughnut data={data} type="doughnut" height={80} width={100} options={options} />

        <div className={styles.foot}>
          <div>
            <h4>{left.label}</h4>
            <span>
              {getCurrency(currency)} {left.value.toLocaleString()}
            </span>
          </div>
          <div style={{ textAlign: 'right', marginRight: 5 }}>
            <h4>{right.label}</h4>
            <span>
              {getCurrency(currency)} {right.value.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.linkBlock}>
        <Link to={link}>View Details</Link>
      </div>
    </div>
  );
}

export default FinancingInfoCard;
