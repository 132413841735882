import React from 'react';
import styles from './style.module.scss';
import QuoteCard from '../../../components/cards/quotecard';

const QuoteList = ({title, data, invoiceDueDate,  onQuoteAcceptanceOrRejection, link, linkTitle, ...rest}: any) => {
  const ListInvoice = data.map((el: any, index: string)=> {
    return (
      <QuoteCard
        data={el} invoiceDueDate={invoiceDueDate}
        onQuoteAcceptanceOrRejection={onQuoteAcceptanceOrRejection}
        key={index+'inCard'}
      />
    )
  });

  return (
    <div className={styles.cardContainer}>
      {ListInvoice}
    </div>
  )
}

export default QuoteList;