import React from 'react';
import styles from './index.module.scss';

interface Tag {
  type: 'New' | 'Pending' | 'Rejected' | 'Accepted';
  text: string;
  isActive?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const CardTag = ({ text, type, onClick, isActive }: Tag) => {
  if (onClick) {
    return (
      <button className={`${styles[type]} ${ isActive ? styles.active : '' }`} style={{ cursor: 'pointer' }} onClick={onClick}>
        {text}
      </button>
    );
  }

  return <div className={`${styles[type]}`}>{text}</div>;
};

export default CardTag;
