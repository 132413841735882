import React, { ReactNode, useContext} from "react";
import { Route, Redirect } from "react-router-dom";
import { paths, } from "../../constants";
import MainLayout from "../../layouts/main";
import { isUserAuthenticated } from "../../utils";
import { UserContext } from '../../App'
interface PrivateRouteType {
  children: ReactNode;
  path: string | string[];
  exact?: boolean;
}

function PrivateRoute({ children, ...rest }: PrivateRouteType) {
  const {path} = rest
  console.log("paths", path)
  const userContext = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={() =>
        isUserAuthenticated() &&  userContext.role ? (
          <MainLayout>{children}</MainLayout>
        ) : (
          <Redirect to={paths.login} />
        )
      }
    />
  );
}

export default PrivateRoute;
