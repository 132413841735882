import React, {useState} from "react";
import { CloseIcon, DocumentIcon } from "../../../components/svg";
import Upload from "../../../components/upload";
import styles from "./style.module.scss";
import {getFileDetails} from '../../../utils'

interface fileProps {
  id: string
  onUpload: Function
}

const FileUploadBlock = ({id, onUpload}:fileProps) => {
  //TODO: handleChange to be updated based on props. Below implementation if for testing purpose.
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const handleChange = (e: any) => {
    let files = e.target.files;
    if (files[0]) {
      let name = `...${files[0].name.slice(-30)}`;
      const file = URL.createObjectURL(files[0]);
      //set file state variable
      setFileName(name)
      setFile(file);
      getFileDetails(files[0]).then(
        (fileDetails) => onUpload(fileDetails)
      )
      // onUpload(files[0]);
    }
  };

  //TODO: handleRemove to be updated based on props. Below implementation if for testing purpose.
  const handleRemove = (e: any) => {
    //clear file state variable
    setFile('')
    onUpload('')
  };
  return (
    <div className={`row ${styles.uploadBlock}`}>
      <div className="col-6">
        <Upload
          id={id}
          type="doc"
          onChange={handleChange}
        />
      </div>

      {file !== '' && <div className={`col-6 ${styles.fileDetails}`}>
        <span>{fileName}</span>
        <div className="d-flex align-items-center">
          <DocumentIcon width="32" height="36" />
          <div className={styles.closeIcon}>
            <CloseIcon onClick={handleRemove} />
          </div>
        </div>
      </div>
      }
    </div>
  );
}

export default FileUploadBlock;
