import React from "react";
// import { paths } from "../../constants";
// import ModuleInfoCard from "../../components/cards/moduleInfo";

function Dashboard() {
  // const modules = [
  //   {
  //     count: 15,
  //     name: "Suppliers",
  //     link: paths.suppliers,
  //   },
  //   {
  //     count: 94,
  //     name: "Invoices",
  //     link: paths.invoices,
  //   },
  //   {
  //     count: 4,
  //     name: "Partners",
  //     link: paths.partners,
  //   },
  // ];
  return (
    <div>
      <section style={{ padding: "20px" }}>Dashboard</section>
      {/* <section>
        <div className="row">
          {modules.map((moduleInfo) => (
            <div className="col-4" key={moduleInfo.name}>
              <ModuleInfoCard details={moduleInfo} />
            </div>
          ))}
        </div>
      </section> */}
    </div>
  );
}

export default Dashboard;
