import React, {useState} from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import styles from "./UserForm.module.scss";


function UserForm({handleChange, formData, setFile}:any) {
  const [contact, setContact] = useState('234');

  return (
    <>
      <div className={`row ${styles.formSection}`}>
        <div className="col-12">
          <div className="form-group">
            <label className="form-label" htmlFor="name">
              Name:
            </label>
            <input
              type="text"
              id="name"
              onChange={handleChange}
              required
            />
          </div>
          
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="contact">
              Tel:
            </label>
            <PhoneInput
              value={contact}
              onChange={(phone)=> {
                const e = {
                  target: {
                    id: "contact",
                    value: phone
                  }
                }
                setContact(phone);
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className={`row ${styles.formSection}`}>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="designation">
              Designation:
            </label>
            <input
              type="text"
              id="designation"
              onChange={handleChange}
              required
            />
          </div>
        </div>
        </div>
      <div className={`row ${styles.formSection}`}>
        <div className={styles.buttonGroup}>
          <button type="submit" className={`${styles.btnRed}`}>Add User</button>
          <button onClick={()=> {console.log("Cancel supplier")}}className={`${styles.btnGrey}`}>Cancel</button>
        </div>
      </div>
    </>
  );
}

export default UserForm;
