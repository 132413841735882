import React from "react";
import logo from "../../assets/img/sc-logo.png";
import styles from "./style.module.scss";

function Footer({ variant }: { variant?: "mainLayoutVariant" }) {
  const footerClassNames = variant
    ? `${styles.footer} ${styles[variant]}`
    : styles.footer;
  return (
    <footer className={footerClassNames}>
      <div className="row">
        <div className="col-6 d-flex align-items-center">
          &#169; Simplified Credit. All Rights Reserved
        </div>
        <div className="col-6 d-flex align-items-center justify-content-end">
          <span className={styles.poweredByLabel}>Powered by</span>
          <div className={styles.poweredBylLogo}>
            <img src={logo} alt="Powered by Simplified Credit" />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
